import axios from 'axios';
import config from '../config';
import store from '../store';

export const handleCreateConsent = async (type, value, callback) => {
  const token = await window.LoginAPI.getToken();
  const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
  const headers = { Authorization: `Bearer ${token}` };
  const query = `
    mutation {
      createCustomerData(input: { key: "${type}", value: "${value.toString()}" }) {
        status
        customerData {
          id
        }
      }
    }
  `;

  try {
    const response = await axios.post(url, { query }, { headers });
    if (response.data.data.createCustomerData && response.data.data.createCustomerData.status === true) {
      if (typeof callback === 'function') {
        callback();
      }
    } else if (response.data.errors) {
      response.data.errors.forEach(error => {
        console.log(error);
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export default {
  data() {
    return {
      config,
    };
  },
  computed: {
    userConsents: {
      get: () => store.getters.userConsents,
      set: consents => store.commit('setUserConsents', consents),
    },
  },
  methods: {
    async getConsents(callback) {
      const consentTypes = [];
      config.consentTypes.forEach(type => {
        consentTypes.push(`"${type}"`);
      });

      const token = await window.LoginAPI.getToken();
      const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
      const headers = { Authorization: `Bearer ${token}` };
      const query = `
        query {
          getCustomerData(input: { key: [${consentTypes}] }) {
            items {
              id
              customerId
              key
              value
              createdAt
            }
          }
        }
      `;

      try {
        const response = await axios.post(url, { query }, { headers });
        if (response.data.data.getCustomerData && response.data.data.getCustomerData.items.length > 0) {
          const consents = [];
          response.data.data.getCustomerData.items.forEach(consent => {
            consents.push({ id: consent.id, type: consent.key, value: consent.value });

            if (typeof this.consents[consent.key] === 'object') {
              this.consents[consent.key][consent.value] = consent.value;
            } else {
              this.consents[consent.key] = consent.value;
            }
          });

          this.userConsents = consents;
        }

        if (typeof callback === 'function') {
          callback();
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    checkConsent(type, value) {
      let response = false;

      this.userConsents.forEach(consent => {
        if (consent.type === type && consent.value === value) {
          response = true;
        }
      });

      return response;
    },
    async createConsent(type, value, callback) {
      await handleCreateConsent(type, value, () => {
        if (typeof callback === 'function') {
          callback();
        }
      });
    },
    async deleteConsent(type, value, callback) {
      const token = await window.LoginAPI.getToken();
      const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
      const headers = { Authorization: `Bearer ${token}` };
      const getQuery = `
        query {
          getCustomerData(input: { key: "${type}", value: "${value}" }) {
            items {
              id
            }
          }
        }
      `;

      const getResponse = await axios.post(url, { query: getQuery }, { headers });
      let item = {};
      if (getResponse.data.data.getCustomerData && getResponse.data.data.getCustomerData.items.length > 0) {
        item = getResponse.data.data.getCustomerData.items[0];
      }

      if (item && item.id) {
        const query = `
          mutation {
            deleteCustomerData(input: { id: "${item.id}" }) {
              status
            }
          }
        `;

        try {
          const response = await axios.post(url, { query }, { headers });
          if (response.data.data.deleteCustomerData && response.data.data.deleteCustomerData.status === true) {
            if (typeof callback === 'function') {
              callback();
            }
          } else if (response.data.errors) {
            response.data.errors.forEach(error => {
              console.log(error);
            });
          }
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    },
  },
};
