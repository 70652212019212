var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3 sidebar"},[_c('div',{staticClass:"sidebar-inner"},[(_vm.showHeadInMobile)?_c('nav',{staticClass:"nav-mobile d-block d-sm-none"},[_vm._m(0),_c('div',{staticClass:"scroll-wrapper"},[_c('ul',{staticClass:"nav"},_vm._l((_vm.navItems),function(item,index){return _c('li',{key:index,staticClass:"nav-item",class:[
              _vm.navActive.route === item.name ? 'active' : '',
              _vm.navActive.route === 'home' && item.isHome === true ? 'active' : '',
              item.hidden === true ? 'd-none' : '' ],attrs:{"data-view":_vm.navItems[index].name}},[(item.name != 'logout')?_c('router-link',{staticClass:"nav-link",attrs:{"to":'/' + item.name}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])]):_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)}),0)]),_vm._m(1)]):_c('div',{staticClass:"nav-back"},[_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-chevron-left"}),_vm._v("\n        "+_vm._s(_vm.$t('back'))+"\n      ")])]),_c('div',{staticClass:"profile-head pt-sm-0 pb-sm-0 py-md-5",class:{
        'py-0': _vm.navActive.route === 'bookmarks',
        'pt-4 pb-5': _vm.navActive.route === 'profile',
        'hide-in-mobile': !_vm.showHeadInMobile,
      }},[_c('div',{staticClass:"profile-head-inner d-md-flex justify-content-start align-items-center flex-wrap",class:{ 'd-none': _vm.navActive.route === 'bookmarks' }},[_c('div',{staticClass:"avatar mx-auto m-sm-0"},[(this.userProfile && this.userProfile.avatar && _vm.hasAvatar)?_c('span',{style:({ backgroundImage: 'url(' + this.userProfile.avatar + ')' }),attrs:{"width":"150","height":"150","alt":""}}):_c('span',[_c('i',{staticClass:"fas fa-user"})]),(_vm.showUpload)?_c('div',{staticClass:"avatar-upload-select d-flex justify-content-around align-items-center px-2"},[_c('div',{staticClass:"avatar-upload-select-upload"},[_c('i',{staticClass:"fas fa-camera",class:{ 'text-danger': _vm.errors.avatar },on:{"click":function($event){$event.preventDefault();return _vm.$refs.file.click()}}})]),_vm._m(2),_c('div',{staticClass:"avatar-upload-select-delete",on:{"click":function($event){$event.preventDefault();return _vm.removeAvatar()}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]):_vm._e(),_c('div',{staticClass:"avatar-upload",on:{"click":function($event){$event.preventDefault();_vm.showUpload = !_vm.showUpload}}},[_c('i',{class:[_vm.showUpload ? 'fal fa-times' : 'fas fa-camera']})]),_c('input',{ref:"file",staticClass:"d-none",attrs:{"id":"avatar-input","type":"file"},on:{"change":function($event){return _vm.uploadAvatar(_vm.fileInput)}}})]),(_vm.errors.avatar)?_c('div',{staticClass:"flex-grow-1 invalid-feedback"},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.errors.avatar))+"\n        ")]):_vm._e()])]),_c('nav',{staticClass:"nav-desktop d-none d-sm-block"},[_c('ul',{staticClass:"nav flex-column"},_vm._l((_vm.navItems),function(item,index){return _c('li',{key:index,staticClass:"nav-item",class:[
            _vm.navActive.route === item.name ? 'active' : '',
            _vm.navActive.route === 'home' && item.isHome === true ? 'active' : '',
            item.hidden === true ? 'd-none' : '' ],attrs:{"data-view":_vm.navItems[index].name}},[(item.name != 'logout')?_c('router-link',{staticClass:"nav-link",attrs:{"to":'/' + item.name}},[_c('i',{class:'fal fa-' + item.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])]):_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_c('i',{class:'fal fa-' + item.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(item.name)))])])],1)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"arrow-left"},[_c('i',{staticClass:"fa fa-chevron-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"arrow-right"},[_c('i',{staticClass:"fa fa-chevron-right"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-upload-select-seperator"},[_c('i')])}]

export { render, staticRenderFns }