<template>
  <div id="login-app" :class="[$props.modal ? 'is-modal' : '', `template-${$props.template}`]">
    <div class="container-fluid">
      <div class="row">
        <Sidebar v-if="showSidebar" :navActive="navActive" :show-nav-in-mobile="showNavInMobile" :show-head-in-mobile="showHeadInMobile" />
        <router-view />
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import { updateLoginAnchor, dispatchEvent } from './utils/common';
import { createLoginDropdown } from './utils/dropdown';
import { closeModal } from './utils/modal';
import Sidebar from './components/Sidebar.vue';
import Loading from './components/Loading.vue';

require('custom-event-polyfill');

export default {
  data() {
    return {
      loading: false,
      successCallbackFunction: null,
      showSidebar: false,
      showNavInMobile: false,
      showHeadInMobile: false,
      navActive: {},
    };
  },
  props: {
    modal: Boolean,
    loginAnchorId: String,
    locale: String,
    successCallback: Function,
    forceRegister: Boolean,
    getParams: String,
    template: {
      type: String,
      default: 'default',
    },
  },
  components: {
    Sidebar,
    Loading,
  },
  methods: {
    saveUserProfile(data, callback) {
      this.$store.commit('setUserProfile', data);
      if (typeof callback === 'function') {
        callback();
      }
    },
  },
  watch: {
    $route(route) {
      this.showSidebar =
        !this.$props.modal && route.name !== 'auth' && route.name !== 'recoverPassword' && route.name !== 'resetPassword' && route.name !== 'loginWithToken'
          ? true
          : false;
      this.showNavInMobile = route.name == 'home' || route.name == 'profile' ? true : false;
      this.showHeadInMobile = route.name == 'home' || route.name == 'profile' ? true : false;
      this.navActive = {
        index: (route.matched[0] && route.matched[0].props.default && route.matched[0].props.default.navIndex) || 0,
        route: route.name || 'profile',
      };
    },
  },
  created() {
    if (this.getParams !== undefined) {
      if (/\/id/i.test(window.location.pathname)) {
        const loc = window.location;
        const url = `${loc.protocol}//${loc.hostname}${loc.pathname}${loc.hash}`;
        window.history.pushState('object', window.document.title, url);
      }
      const getParams = JSON.parse(this.getParams);
      if (typeof getParams === 'object' && getParams.view && getParams.code) {
        if (getParams.view === 'verify-email') {
          window.LoginAPI.getToken().then(token => {
            if (token !== null) {
              this.$router.push({ name: 'verifyEmail', params: { ...getParams } });
            } else {
              this.successCallbackFunction = () => {
                this.$router.push({ name: 'verifyEmail', params: { ...getParams } });
              };
            }
          });
        }

        if (getParams.view === 'confirm-delete') {
          window.LoginAPI.getToken().then(token => {
            if (token !== null) {
              this.$router.push({ name: 'confirmDelete', params: { ...getParams } });
            } else {
              this.successCallbackFunction = () => {
                this.$router.push({ name: 'confirmDelete', params: { ...getParams } });
              };
            }
          });
        }
      }
    }
  },
  mounted() {
    window.document.body.classList.add('has-login-app');

    if (this.$router.mode === 'abstract') {
      this.$router.replace('/');
    }
    dispatchEvent('login_fe_init', true);

    if (typeof this.$props.successCallback === 'function') {
      this.successCallbackFunction = this.$props.successCallback;
    }

    window.LoginAPI.on('login_api_login', ({ detail }) => {
      this.loading = true;
      if (detail && detail.loginStatus === true) {
        createLoginDropdown(this.$i18n);
        updateLoginAnchor(this.$props.loginAnchorId, true, detail);
        this.saveUserProfile(detail, () => {
          this.loading = false;
          if (this.$props.modal === true) {
            closeModal();
            if (typeof this.successCallbackFunction === 'function') {
              this.successCallbackFunction();
            }
          } else if (typeof this.successCallbackFunction === 'function') {
            this.successCallbackFunction();
          } else {
            this.$router.push({ name: 'profile' });
          }
        });
      } else {
        updateLoginAnchor(this.$props.loginAnchorId, false);
      }
    });
  },
};
</script>

<style lang="scss">
@import './assets/scss/main.scss';
</style>
