<template>
  <div class="d-flex">
    <a target="_blank" :href="notificationPayload.originalCommentUrl" @click.prevent="updateNotificationStatus('seen', notificationPayload.originalCommentUrl)">
      <div v-if="notificationPayload.commentAuthorDisplayName && notificationServiceType == 'comment_reply'" class="comment-author-display-name">
        {{ notificationPayload.commentAuthorDisplayName }}
        <span>{{ $t('answer_to_comment') }}</span>
      </div>
      <div v-if="notificationPayload.originalCommentContent" class="original-comment-content">
        {{
          notificationPayload.originalCommentContent.length > 100
            ? `${notificationPayload.originalCommentContent.substring(0, 99)}&hellip;`
            : notificationPayload.originalCommentContent
        }}
      </div>
      <ul v-if="notificationPayload.reactions" class="reactions">
        {{
          total(notificationPayload.reactions)
        }}
        {{
          $t('people_reacted')
        }}
        <li v-for="(value, name) in notificationPayload.reactions" :key="value + name">
          <img :src="'https://g.dcdn.lt/misc/comment-fe/img/reactions/reaction-' + name + '.svg'" class="reaction" />
          {{ value }}
        </li>
      </ul>
      <time>{{ Date.parse(notificationCreatedAt) | moment('from', 'now') }}</time>
    </a>
    <i class="far fa-eye" @click.prevent="updateNotificationStatus('seen', false)" />
    <i class="far fa-times" @click.prevent="updateNotificationStatus('deleted', false)" />
  </div>
</template>
<script>
export default {
  props: {
    notificationId: String,
    notificationPayload: Object,
    notificationServiceType: String,
    notificationCreatedAt: String,
  },
  data() {
    return {};
  },
  methods: {
    total(elems) {
      const totalReactions = [];
      Object.entries(elems).forEach(([, val]) => {
        totalReactions.push(val);
      });
      return totalReactions.reduce((total, num) => total + num, 0);
    },
    updateNotificationStatus(status, notificationUrl) {
      this.$parent.$parent.updateNotificationStatus(this.notificationId, status);
      if (notificationUrl) {
        window.open(notificationUrl, '_blank').focus();
      }
    },
  },
};
</script>
