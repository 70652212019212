<template>
  <div class="social-connect my-4 d-flex flex-column justify-content-center">
    <h2>{{ $t('connectSocialMedia') }}</h2>
    <div v-if="!isApp || isFacebookNativeLoginSupported" class="form-check mb-2">
      <label class="form-check-label form-check-toggle">
        <input
          class="form-check-input"
          type="checkbox"
          value="FACEBOOK"
          v-model="userProfile.hasFacebook"
          @click.prevent="userProfile.hasFacebook ? unlinkSocial('FACEBOOK') : linkSocial('FACEBOOK')"
        />
        <span class="d-flex align-items-center">
          <img :src="require(`@/assets/images/fb.svg`)" height="18" class="mr-2" />
          <span>{{ userProfile.hasFacebook ? $t('linkedWith') : $t('linkWith') }} Facebook</span>
        </span>
      </label>
    </div>
    <div v-if="(!isApp || isGoogleNativeLoginSupported) && !isBlockedUserAgent" class="form-check mb-2">
      <label class="form-check-label form-check-toggle">
        <input
          class="form-check-input"
          type="checkbox"
          value="GOOGLE"
          v-model="userProfile.hasGoogle"
          @click.prevent="userProfile.hasGoogle ? unlinkSocial('GOOGLE') : linkSocial('GOOGLE')"
        />
        <span class="d-flex align-items-center">
          <img :src="require(`@/assets/images/g.svg`)" height="16" class="mr-2" />
          <span>{{ userProfile.hasGoogle ? $t('linkedWith') : $t('linkWith') }} Google</span>
        </span>
      </label>
    </div>
    <div v-if="!isApp || isAppleNativeLoginSupported" class="form-check mb-2">
      <label class="form-check-label form-check-toggle">
        <input
          class="form-check-input"
          type="checkbox"
          value="APPLE"
          v-model="userProfile.hasApple"
          @click.prevent="userProfile.hasApple ? unlinkSocial('APPLE') : linkSocial('APPLE')"
        />
        <span class="d-flex align-items-center">
          <img :src="require(`@/assets/images/apple.svg`)" height="18" class="mr-2" />
          <span>{{ userProfile.hasApple ? $t('linkedWith') : $t('linkWith') }} Apple</span>
        </span>
      </label>
    </div>
    <div v-if="errors.socialConnect" class="invalid-feedback d-block">
      {{ $t(errors.socialConnect) }}
    </div>
  </div>
</template>
<script>
import store from '@/store';
import loginApi from '@/mixins/loginApi';

require('custom-event-polyfill');

export default {
  mixins: [loginApi],
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    userProfile: {
      get: () => store.getters.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
  },
};
</script>
