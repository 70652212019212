<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <a v-if="!deleted" href="#" class="link-x" v-on:click.prevent="close">
            <i class="fal fa-times"></i>
          </a>
          <div class="modal-body">
            <div v-if="deleted">
              <div class="d-flex justify-content-center align-items-center text-center">
                <h4>{{ $t('deletedMsg') }}</h4>
              </div>
            </div>
            <div v-else-if="codeSetFromUrl">
              <div class="d-flex justify-content-center align-items-center text-center">
                <h4>{{ $t('deleteSentMsgCodeSetFromUrl') }}</h4>
              </div>
            </div>
            <div v-else-if="sent">
              <div class="d-flex justify-content-center align-items-center text-center">
                <h4>{{ $t('deleteSentMsg') }}</h4>
              </div>
            </div>
            <div v-else-if="!loading && activeRenewalSubscriptions.length">
              <p>
                {{ $t('hasRenewalSubscriptions') }}
                <br /><br />
                <ul v-for="(subscription, index) in activeRenewalSubscriptions" :key="index">
                  <li>
                    <a v-if="subscription.url" :href="subscription.url">
                      {{ subscription.service.name }}
                    </a>
                    <span v-else>{{ subscription.service.name }}</span>
                  </li>
                </ul>
              </p>
            </div>
            <div v-else-if="loading">
              <Loading v-if="loading" />
            </div>
            <div v-else>
              <template v-if="targetEmail">
                <h2>{{ $t('deleteAccountConfirm') }}</h2>
                <p class="text-center" v-html="$t('deleteAccountTextEmail', { 'email': targetEmail })"></p>
                <form v-if="targetEmail" action="#" class="needs-validation" novalidate v-on:submit.prevent>
                  <div v-if="errors.unknownError" class="d-block px-4 invalid-feedback text-center">
                    {{ $t(errors.unknownError) }}
                  </div>
                  <div class="form-group mt-4 d-flex justify-content-center align-items-center">
                    <div>
                      <button type="button" class="btn btn-blue"
                        v-on:click.prevent="sendTerminateEmail(targetEmail, language)">{{ $t('yes') }}</button>
                    </div>
                    <div class="w-25"></div>
                    <div>
                      <button type="button" class="btn btn-gray" v-on:click.prevent="close">
                        {{ $t('no') }}
                      </button>
                    </div>
                  </div>
                </form>
              </template>
              <template v-else>
                <p v-html="$t('deleteAccountText')"></p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';

export default {
  mixins: [loginApi],
  data() {
    return {
      loading: false,
      deleted: false,
      sent: false,
      language: 'lt',
      profileUrl: {
        lt: 'https://www.delfi.lt/id/',
        en: 'https://www.delfi.lt/en/id/',
        ru: 'https://www.delfi.lt/ru/id/',
      },
      code: null,
      codeSetFromUrl: false,
      targetEmail: null,
      errors: {},
      activeRenewalSubscriptions: [],
      serviceMap: {
        1: 'delfi-plius',
        2: 'delfi-ad-free',
      },
    };
  },
  props: {
    locale: String,
  },
  components: {
    Loading,
  },
  methods: {
    close() {
      this.$parent.closeDeleteAccount();
    },
    async checkForRenewalSubscriptions() {
      this.loading = true;
      const that = this;
      const baseUrl = that.profileUrl[that.locale];
      window.LoginAPI.getToken().then(async token => {
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(`https://paywall.delfi.lt/paywall/api/user/${this.userProfile.id}/service-info`, { headers }).then((response) => {
          const responseData = response.data;
          if (Array.isArray(responseData)) {
            const data = [];
            response.data.forEach((item) => {
              const valid_till = item.valid_till ? moment(item.valid_till, 'YYYY-MM-DD HH:mm:ss') : false;
              if (item.renewal && valid_till && valid_till.isAfter()) {
                const itemObj = item;
                if (that.serviceMap[item.service.id]) {
                  itemObj.url = `${baseUrl}#/subscriptions`;
                }
                data.push(itemObj);
              }
            });
            this.activeRenewalSubscriptions = data;
          }
          this.loading = false;
        });
      });
    },
  },
  async mounted() {
    if (this.$route.name === 'confirmDelete') {
      Vue.set(this, 'sent', 'true');
      if (this.$route.params.code) {
        Vue.set(this, 'code', this.$route.params.code);
        Vue.set(this, 'codeSetFromUrl', true);
        await this.terminateAccount(this.$route.params.code, this.language);
      }
    }

    if (this.userProfile) {
      if (this.userProfile.email && this.userProfile.emailVerified) {
        Vue.set(this, 'targetEmail', this.userProfile.email);
      }

      this.checkForRenewalSubscriptions();
    }
  },
};
</script>
