<template>
  <div class="col-9 subscriptions">
    <h1 class="view-title text-left">
      {{ $t('subscriptionsH2') }}
    </h1>
    <div class="subscriptions-inner view-inner">
      <div id="omp-profile" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import config from '@/config';
import { isApp } from '@/utils/common';

export default {
  data() {
    return {
      isApp: false,
      config,
    };
  },
  mounted() {
    Vue.set(this, 'isApp', isApp());

    this.timeout = setTimeout(() => {
      const d = Date.now().toString();
      const t = d.substr(0, d.length - 5);
      const container = document.getElementById('omp-profile');
      if (typeof container !== 'undefined') {
        const apiScript = document.createElement('script');
        apiScript.async = true;
        apiScript.setAttribute('src', `https://paywall.delfi.lt/paywall/widgets/profile.min.js?${t}`);
        container.after(apiScript);
        clearTimeout(this.timeout);
      }
    }, 250);
  },
};
</script>
