import config from '../config';

export function validEmail(email) {
  // Return true on empty email
  // to skip validation of email when profile has no email from social sign up.
  if (email === null || email === '') {
    return true;
  }
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validPhone(phone) {
  const re = /^\+\d{11}$/;
  return re.test(phone);
}

export function isMobile() {
  return !!/(android|opera mini|blackberry|pre\/|palm os|palm|hiptop|avantgo|fennec|plucker|xiino|blazer|elaine|iris|3g_t|windows ce|opera mobi|windows ce; smartphone;|windows ce; iemobile|mini 9.5|vx1000|lge |m800|e860|u940|ux840|compal|wireless|mobi|ahong|lg380|lgku|lgu900|lg210|lg47|lg920|lg840|lg370|sam-r|mg50|s55|g83|t66|vx400|mk99|d615|d763|el370|sl900|mp500|samu3|samu4|vx10|xda_|samu5|samu6|samu7|samu9|a615|b832|m881|s920|n210|s700|c-810|_h797|mob-x|sk16d|848b|mowser|s580|r800|471x|v120|rim8|c500foma:|160x|x160|480x|x640|t503|w839|i250|sprint|w398samr810|m5252|c7100|mt126|x225|s5330|s820|htil-g1|fly v71|s302|-x113|novarra|k610i|-three|8325rc|8352rc|sanyo|vx54|c888|nx250|n120|mtk |c5588|s710|t880|c5005|i;458x|p404i|s210|c5100|teleca|s940|c500|s590|foma|samsu|vx8|vx9|a1000|_mms|myx|a700|gu1100|bc831|e300|ems100|me701|me702m-three|sd588|s800|8325rc|ac831|mw200|brew |d88|htc\/|htc_touch|355x|m50|km100|d736|p-9521|telco|sl74|ktouch|m4u\/|me702|8325rc|kddi|phone|lg |sonyericsson|samsung|240x|x320vx10|nokia|sony cmd|motorola|up.browser|up.link|mmp|symbian|smartphone|midp|wap|vodafone|o2|pocket|kindle|mobile|psp|treo)/i.test(
    navigator.userAgent.toLowerCase()
  );
}

export function isPortalRoot() {
  const loginAnchor = document.getElementById('_login');
  return loginAnchor.classList.contains('C-header-actions__button--login');
}

export function isIOS() {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return iOS || isSafari;
}

export function isApp() {
  return /(Delfi|DELFI|Ba1\/[1-3] CFNetwork)/i.test(navigator.userAgent);
}

export function stripTags(string) {
  return string.replace(/(<([^>]+)>)/gi, '');
}

export function updateLoginAnchor(loginAnchorId, loginStatus, data) {
  if (!document.getElementById(loginAnchorId)) {
    return;
  }

  var loginAnchor = document.getElementById(loginAnchorId);

  if (typeof data === 'undefined') {
    data = null;
  }

  if (loginStatus === true) {
    document.getElementById(loginAnchorId).classList.remove('logged-out');
    document.getElementById(loginAnchorId).classList.add('logged-in');
  } else {
    document.getElementById(loginAnchorId).classList.remove('logged-in');
    document.getElementById(loginAnchorId).classList.add('logged-out');
  }

  if (!config.handleLoginAnchorInternally) {
    return;
  }

  if (loginStatus && data) {
    // Append avatar
    if (data.avatar) {
      let avatar = null;
      if (loginAnchor.getElementsByTagName('img').length) {
        avatar = loginAnchor.getElementsByTagName('img')[0];
      } else {
        avatar = document.createElement('img');
      }

      avatar.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
      avatar.width = 26;
      avatar.height = 26;
      avatar.style.backgroundImage = 'url(' + data.avatar + ')';
      loginAnchor.prepend(avatar);

      // Check if avatar really exists, replace with name letter alternatively
      var request = new XMLHttpRequest();
      request.open('GET', data.avatar, true);
      request.onload = () => {
        if (request.status >= 400) {
          const userName = data.firstName ? data.firstName : data.displayName;
          var hash = 0;
          for (var i = 0; i < userName.length; i += 1) {
            hash = userName.charCodeAt(i) + ((hash << 5) - hash);
          }

          const c = (hash & 0x00ffffff).toString(16).toUpperCase();
          const bgColor = '00000'.substring(0, 6 - c.length) + c;

          loginAnchor.getElementsByTagName('img')[0].remove();

          var loginLetter = document.createElement('span');
          loginLetter.id = 'login-letter';
          loginLetter.className = 'login-letter';
          loginLetter.innerHTML = '<span>' + userName.substring(0, 1) + '</span>';
          loginLetter.style.backgroundColor = '#' + bgColor;
          loginLetter.style.width = '25px';
          loginLetter.style.height = '25px';
          loginLetter.style.display = 'inline-block';
          loginLetter.style.textAlign = 'center';
          loginLetter.style.textTransform = 'uppercase';
          loginLetter.style.lineHeight = '25px';
          loginLetter.style.borderRadius = '25px';
          loginAnchor.prepend(loginLetter);
        }
      };
      request.send();
    }

    if (isMobile()) {
      // Hide login text
      const loginText = loginAnchor.getElementsByClassName('login-text')[0];
      loginText.style.display = 'none';

      // Append login name
      const loginName = document.createElement('span');
      const loginNameString = data.firstName && data.lastName ? data.firstName.substring(0, 1) + '. ' + data.lastName : data.displayName;
      loginName.id = 'login-name';
      loginName.className = 'login-name';
      loginName.innerHTML = loginNameString.substring(0, 20);
      loginName.style.color = '#000';
      loginAnchor.append(loginName);
    }
  } else {
    // Remove avatar
    const avatar = loginAnchor.getElementsByTagName('img');
    if (avatar.length) {
      avatar[0].remove();
    }

    // Remove loggin letter
    const loginLetter = loginAnchor.getElementsByClassName('login-letter');
    if (loginLetter.length) {
      loginLetter[0].remove();
    }

    if (isMobile()) {
      // Remove login name
      const loginName = loginAnchor.getElementsByClassName('login-name');
      if (loginName.length) {
        loginName[0].remove();
      }

      // Show login text
      const loginText = loginAnchor.getElementsByClassName('login-text')[0];
      loginText.style.display = 'inline';
    }
  }
}

export function handleLoginAnchorClick(loginAnchorId) {
  var loginAnchor = document.getElementById(loginAnchorId);
  var loginState = loginAnchor.classList.contains('logged-in') || false;
  if (loginState) {
    const loginDropdown = document.getElementsByClassName('login-dropdown');
    if (loginDropdown.length) {
      loginDropdown[0].classList.toggle('visible');
      document.body.addEventListener('click', event => {
        if (!loginAnchor.contains(event.target) && !loginDropdown[0].contains(event.target)) {
          if (loginDropdown[0].classList.contains('visible')) {
            loginDropdown[0].classList.remove('visible');
          }
        }
      });
    }
  } else {
    let locale = 'lt';

    if (window.location.hostname === 'ru.delfi.lt' || window.location.pathname.indexOf('/ru/') === 0) {
      locale = 'ru';
    } else if (window.location.hostname === 'en.delfi.lt' || window.location.pathname.indexOf('/en/') === 0) {
      locale = 'en';
    } else if (window.location.hostname === 'pl.delfi.lt' || window.location.pathname.indexOf('/pl/') === 0) {
      locale = 'en';
    }

    window.showLoginEmbed('login-modal', {
      modal: true,
      loginAnchorId,
      locale,
    });
  }
}

export function updateNotificationsCount(loginAnchorId, notificationsCount) {
  if (!document.getElementById(loginAnchorId)) {
    return;
  }
  const loginAnchor = document.getElementById(loginAnchorId);
  const notificationsAnchorId = 'notifications-count';
  const notificationsAnchorIdFl = '_notifications';
  if (loginAnchor.classList.contains('logged-in')) {
    if (document.getElementById(notificationsAnchorIdFl)) {
      if (notificationsCount > 0) {
        document.getElementById(notificationsAnchorIdFl).className = 'has-notification';
      }
    } else if (!document.getElementById(notificationsAnchorId) && notificationsCount > 0) {
      const notificationsCountContainer = document.createElement('a');
      notificationsCountContainer.setAttribute('id', notificationsAnchorId);
      notificationsCountContainer.setAttribute('href', 'https://www.delfi.lt/id/#/notifications');
      notificationsCountContainer.innerHTML = notificationsCount;

      notificationsCountContainer.style.position = 'absolute';
      notificationsCountContainer.style.right = '76px';
      notificationsCountContainer.style.top = '-4px';
      notificationsCountContainer.style.backgroundColor = '#e6004e';
      notificationsCountContainer.style.color = '#FFFFFF';
      notificationsCountContainer.style.fontSize = '11px';
      notificationsCountContainer.style.fontWeight = '600';
      notificationsCountContainer.style.borderRadius = '50%';
      notificationsCountContainer.style.minWidth = '18px';
      notificationsCountContainer.style.padding = '2px 0';
      notificationsCountContainer.style.textAlign = 'center';
      notificationsCountContainer.style.textDecoration = 'none';
      notificationsCountContainer.style.zIndex = '99999';

      loginAnchor.parentNode.insertBefore(notificationsCountContainer, loginAnchor.nextSibling);
    } else if (notificationsCount > 0) {
      if (document.getElementById(notificationsAnchorId).offsetParent === null) {
        document.getElementById(notificationsAnchorId).style.display = 'inline-block';
      }
      document.getElementById(notificationsAnchorId).innerHTML = notificationsCount;
    } else if (notificationsCount === 0 && document.getElementById(notificationsAnchorId)) {
      document.getElementById(notificationsAnchorId).style.display = 'none';
    }
  }
}

export function dispatchEvent(eventName, detail) {
  const event = new CustomEvent(eventName, { detail });
  window.dispatchEvent(event);
}
