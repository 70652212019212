import axios from 'axios';
import { validEmail, dispatchEvent, isIOS } from '../utils/common';
import { handleCreateConsent } from '../mixins/userDataApi';

const apiErrorsMap = {
  DisplayNameNotAvailableError: 'displayName',
  EmailNotAvailableError: 'email',
  InvalidEmailError: 'email',
  InvalidPasswordError: 'password',
  LastLoginMethodError: 'socialConnect',
  IDP_ALREADY_LINKED: 'socialConnect',
  UnexpectedSystemError: 'avatar',
  NotImageFileError: 'avatar',
  UploadSizeLimitError: 'avatar',
  IncorrectPasswordError: 'currentPassword',
  PasswordMissingError: 'missingPassword',
};

export default {
  computed: {
    isApp() {
      return window.LoginAPI && window.LoginAPI.isNativeApp();
    },
    isFacebookNativeLoginSupported() {
      return window.LoginAPI.isSupportedNativeLoginProvider && window.LoginAPI.isSupportedNativeLoginProvider('FACEBOOK');
    },
    isGoogleNativeLoginSupported() {
      return window.LoginAPI.isSupportedNativeLoginProvider && window.LoginAPI.isSupportedNativeLoginProvider('GOOGLE');
    },
    isAppleNativeLoginSupported() {
      var version = 0;
      var vm = navigator.userAgent.match(/DelfiLT(?:www|rudelfi)\/([0-9]+)/);
      if (vm) {
        version = parseInt(vm[1]);
      }

      return (isIOS && version >= 605) || false;
    },
    isBlockedUserAgent() {
      // Add all user agents which are blocked
      return navigator.userAgent.match(/(FBAN|FBIOS|LinkedInApp)/gm);
    },
    userProfile() {
      return this.$store.state.userProfile;
    },
  },
  methods: {
    async checkEmail(email) {
      this.errors = {};
      this.loading = true;

      if (email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      const reCaptchaVersion = await window.LoginAPI.getRecaptchaVersion('checkEmail-g-recaptcha');
      const response = await window.LoginAPI.checkEmail({ email, reCaptchaVersion });

      this.loading = false;

      if (response.data) {
        return response.data.available;
      } else if (response.errors && response.errors.length) {
        if (response.errors[0].message.includes('CaptchaError: Captcha score too low')) {
          await window.LoginAPI.initRecaptchaV2('checkEmail-g-recaptcha');
          return;
        }

        this.errors = {};
        this.handleApiErrors(response.errors);
      }
    },
    async login(email, password) {
      this.errors = {};
      this.loading = true;

      if (email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      if (password === null) {
        this.errors.password = 'emptyPassword';
        this.loading = false;
        return;
      }

      const reCaptchaVersion = await window.LoginAPI.getRecaptchaVersion('login-g-recaptcha');
      const response = await window.LoginAPI.login({ email, password, reCaptchaVersion });

      this.loading = false;

      if ((!response.data && !response.errors) || (response.data && !response.data.status)) {
        this.errors.password = 'invalidPassword';
      } else if (response.errors && response.errors.length) {
        if (response.errors[0].message.includes('CaptchaError: Captcha score too low')) {
          await window.LoginAPI.initRecaptchaV2('login-g-recaptcha');
          return;
        }

        this.errors = {};
        this.handleApiErrors(response.errors);
      }
    },
    loginSocial(type) {
      this.errors = {};

      window.LoginAPI.resolveAuthMethod(type, 'login').then(response => {
        if (response === undefined) {
          dispatchEvent('login_fe_signUp', type);
          return;
        }

        if (response.signup) {
          if (confirm(this.$t('socialLoginNotExist'))) {
            const signup = email => {
              response
                .signup(email)
                .then(() => {
                  dispatchEvent('login_fe_signUp', type);
                })
                .catch(e => {
                  if (e.type === 'ResolveAuthNoEmail') {
                    const newEmail = prompt(`${this.$t('socialResolveAuthNoEmail')}:`);
                    if (newEmail) {
                      signup(newEmail);
                    }
                  }
                  if (e.type === 'EmailNotAvailableError') {
                    alert(`${this.$t('socialEmailNotAvailableError')}:`);
                  }
                });
            };
            signup();
          }
        }
      });
    },
    logout() {
      this.loading = true;
      window.LoginAPI.logout().then(() => {
        this.loading = false;
      });
    },
    async signup(data) {
      this.errors = {};
      this.loading = true;

      if (data.email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(data.email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      if (data.password === null) {
        this.errors.password = 'emptyPassword';
        this.loading = false;
        return;
      }

      if (data.password.length < 8) {
        this.errors.password = 'tooShortPassword';
        this.loading = false;
        return;
      }

      if (this.tosAgreed === false) {
        this.errors.tosAgreed = 'tosNotAgreed';
        this.loading = false;
        return;
      }

      const emailSplit = data.email.split('@');
      const rand = Math.floor(Math.random() * (999 - 100 + 1) + 100);

      const reCaptchaVersion = await window.LoginAPI.getRecaptchaVersion('signup-g-recaptcha');
      const response = await window.LoginAPI.signup(
        {
          email: data.email,
          firstName: data.firstName,
          phone: data.phone,
          displayName: emailSplit[0] + rand,
          password: data.password,
          reCaptchaVersion,
        },
        true
      );

      this.loading = false;

      if (response.data !== null) {
        if (response.data.status === true) {
          if (this.marketingConsent === true) {
            handleCreateConsent('marketingConsent', true);
          }

          if (this.delfiPlusMarketingConsent === true) {
            handleCreateConsent('dplius_filter_mail', '4');
          }

          if (this.delfiPlusContentConsent === true) {
            handleCreateConsent('dplius_filter_mail', '1');
          }

          dispatchEvent('login_fe_signUp', 'DELFI');
        }
      } else if (response.errors && response.errors.length) {
        if (response.errors[0].message.includes('CaptchaError: Captcha score too low')) {
          await window.LoginAPI.initRecaptchaV2('signup-g-recaptcha');
          return;
        }

        this.errors = {};
        this.handleApiErrors(response.errors);
      } else {
        this.errors.socialAuthError = 'somethingWentWrongError';
      }
    },
    signupSocial(type) {
      this.errors = {};

      if (this.tosAgreed === false) {
        this.errors.tosAgreed = 'tosNotAgreed';
        return;
      }

      window.LoginAPI.resolveAuthMethod(type, 'signup').then(response => {
        if (response === undefined) {
          dispatchEvent('login_fe_signUp', type);
          return;
        }
        if (response.signup) {
          const signup = email => {
            response
              .signup(email)
              .then(() => {
                dispatchEvent('login_fe_signUp', type);
              })
              .catch(e => {
                if (e.type === 'ResolveAuthNoEmail') {
                  const newEmail = prompt(`${this.$t('socialResolveAuthNoEmail')}:`);
                  if (newEmail) {
                    signup(newEmail);
                  }
                }
                if (e.type === 'EmailNotAvailableError') {
                  alert(`${this.$t('socialEmailNotAvailableError')}:`);
                }
              });
          };
          const newEmail = prompt(`${this.$t('socialResolveAuthNoEmail')}:`);
          if (newEmail) {
            signup(newEmail);
          }
        }
        if (response.login) {
          if (confirm(this.$t('socialSignupExist'))) {
            response.login();
          }
        }
      });
    },
    async asyncSignupSocial(type, callback) {
      this.errors = {};

      if (this.tosAgreed === false) {
        this.errors.tosAgreed = 'tosNotAgreed';
        return;
      }

      const response = await window.LoginAPI.resolveAuthMethod(type, 'signup');
      if (response === undefined) {
        dispatchEvent('login_fe_signUp', type);
        if (typeof callback !== 'undefined') {
          await callback(true);
        }
        return;
      }

      if (response.signup) {
        const signup = async (email, callback) => {
          response
            .signup(email)
            .then(async callback => {
              dispatchEvent('login_fe_signUp', type);
              if (typeof callback !== 'undefined') {
                await callback(true);
              }
            })
            .catch(async e => {
              if (e.type === 'ResolveAuthNoEmail') {
                const newEmail = prompt(`${this.$t('socialResolveAuthNoEmail')}:`);
                if (newEmail) {
                  await signup(newEmail, callback);
                }
              }
              if (e.type === 'EmailNotAvailableError') {
                alert(`${this.$t('socialEmailNotAvailableError')}:`);
              }
            });
        };

        const newEmail = prompt(`${this.$t('socialResolveAuthNoEmail')}:`);
        if (newEmail) {
          signup(newEmail, callback);
        }
      }

      if (response.login) {
        if (confirm(this.$t('socialSignupExist'))) {
          await response.login();
          if (typeof callback !== 'undefined') {
            await callback(false);
          }
        }
      }
    },
    reloadProfile(refreshAvatar) {
      if (typeof refreshAvatar == 'undefined') {
        refreshAvatar = false;
      }

      this.loading = true;
      window.LoginAPI.getCustomers().then(response => {
        this.loading = false;
        this.userProfile = response.data;
        this.email = response.data.email;
        this.initialEmail = response.data.email;
        this.displayName = response.data.displayName;
        if (refreshAvatar) {
          response.data.avatar = response.data.avatar + '?t=' + Date.now();
        }
        this.$store.dispatch('setUserProfile', response.data);
      });
    },
    updateProfile() {
      this.errors = {};
      this.loading = true;

      if ((this.userProfile.email === null || this.userProfile.email === '') && this.userProfile.hasPassword) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(this.userProfile.email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      if (this.userProfile.phone !== null && this.userProfile.phone !== '' && typeof this.userProfile.phone !== 'undefined') {
        if (!/^\+?\d+$/i.test(this.userProfile.phone)) {
          this.errors.phone = 'invalidPhone';
          this.loading = false;
          return;
        }
      }

      window.LoginAPI.updateCustomer({
        email: this.email,
        displayName: this.displayName,
        phone: this.userProfile.phone,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
      }).then(response => {
        this.loading = false;
        if (response.data && response.data.success === true) {
          this.reloadProfile();
          this.isEditing = false;
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        }
      });
    },
    updatePassword() {
      this.errors = {};
      this.loading = true;

      if (this.userProfile.hasPassword === true) {
        if (this.currentPassword == null) {
          this.errors.currentPassword = 'currentPasswordEmpty';
          this.loading = false;
          return;
        }
      }

      if (this.password == null) {
        this.errors.password = 'newPasswordEmpty';
        this.loading = false;
        return;
      }

      if (this.password.length < 8) {
        this.errors.password = 'tooShortPassword';
        this.loading = false;
        return;
      }

      if (this.repeatPassword == null) {
        this.errors.repeatPassword = 'emptyRepeatPassword';
        this.loading = false;
        return;
      }

      if (this.password !== this.repeatPassword) {
        this.errors.repeatPassword = 'passwordsDoNotMatch';
        this.loading = false;
        return;
      }

      window.LoginAPI.updateCustomer({
        passwordOld: this.currentPassword,
        passwordNew: this.password,
      }).then(response => {
        if (response.data && response.data.success === true) {
          this.passwordUpdated = true;
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }
      });
    },
    uploadAvatar(fileInput) {
      this.errors = {};
      this.$parent.loading = true;
      window.LoginAPI.uploadAvatar(fileInput).then(uploadResponse => {
        if (uploadResponse.data && uploadResponse.data === true) {
          this.reloadProfile();
          this.hasAvatar = true;
        } else if (uploadResponse.errors.length) {
          this.errors = {};
          this.handleApiErrors(uploadResponse.errors);
        } else {
          this.errors.avatar = 'UnknownAvatarUploadError';
        }

        this.showUpload = false;
        this.$parent.loading = false;
      });
    },
    removeAvatar() {
      this.errors = {};
      this.$parent.loading = true;
      window.LoginAPI.removeAvatar().then(removeResponse => {
        if (removeResponse.data && removeResponse.data === true) {
          this.reloadProfile();
          this.hasAvatar = false;
        } else {
          this.errors.avatar = 'UnknownAvatarDeleteError';
        }

        this.showUpload = false;
        this.$parent.loading = false;
      });
    },
    linkSocial(type) {
      this.errors = {};
      window.LoginAPI.resolveAuthMethod(type, 'link').then(response => {
        if (response === undefined) {
          this.reloadProfile();
          dispatchEvent('login_fe_socialLink', type);
        } else {
          this.errors = {};
          this.handleApiErrors(response.errors);
        }
      });
    },
    unlinkSocial(type) {
      this.errors = {};
      window.LoginAPI.unlinkAuthMethod(type).then(response => {
        if (response.data) {
          if (response.data.status === true) {
            this.reloadProfile();
            dispatchEvent('login_fe_socialUnlink', type);
          }
        } else {
          this.errors = {};
          this.handleApiErrors(response.errors);
        }
      });
    },
    async recoverPassword(email) {
      this.errors = {};
      this.loading = true;

      if (email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      const reCaptchaVersion = await window.LoginAPI.getRecaptchaVersion('recoverPassword-g-recaptcha');
      const response = await window.LoginAPI.recoverPassword({ email, reCaptchaVersion });

      this.loading = false;

      if (response.data && response.data.success === true) {
        this.sent = true;
      } else {
        if (response.errors[0].message.includes('CaptchaError: Captcha score too low')) {
          await window.LoginAPI.initRecaptchaV2('recoverPassword-g-recaptcha');
          return;
        }

        this.errors.email = 'emailNotFound';
      }
    },
    resetPassword(code, password, passwordRepeat) {
      this.errors = {};
      this.loading = true;

      if (code === null) {
        this.errors.code = 'emptyCode';
        this.codeSetFromUrl = false;
        this.loading = false;
        return;
      }

      if (password === null) {
        this.errors.password = 'emptyPassword';
        this.loading = false;
        return;
      }

      if (password.length < 8) {
        this.errors.password = 'tooShortPassword';
        this.loading = false;
        return;
      }

      if (passwordRepeat === null) {
        this.errors.repeatPassword = 'emptyRepeatPassword';
        this.loading = false;
        return;
      }

      if (password !== passwordRepeat) {
        this.errors.repeatPassword = 'passwordsDoNotMatch';
        this.loading = false;
        return;
      }

      window.LoginAPI.resetPassword({ code, password }).then(response => {
        this.loading = false;
        if (response.data && response.data.success === true) {
          this.reset = true;
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }

        this.loading = false;
      });
    },
    sendVerifyEmail(language) {
      this.errors = {};
      this.loading = true;
      window.LoginAPI.resendEmailChangeEmail({ language }).then(response => {
        this.loading = false;
        if (response.data.success === true) {
          this.verifyEmailSent = true;
        } else if (response.data.status === false) {
          this.errors.unknownError = 'verifyEmailAlreadySentError';
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }
      });
    },
    verifyEmail(code) {
      this.errors = {};
      this.loading = true;
      if (code !== null) {
        window.LoginAPI.verifyEmail({ code }).then(response => {
          this.loading = false;
          if (response.data.success === true) {
            this.reloadProfile();
          } else if (response.errors && response.errors.length) {
            this.errors = {};
            this.handleApiErrors(response.errors);
          } else {
            this.errors.unknownError = 'somethingWentWrongError';
          }
        });
        this.verifyEmailSent = false;
      }
    },
    sendTerminateEmail(email, language) {
      this.errors = {};
      const postData = { language, email };
      window.LoginAPI.sendDeleteCustomerEmail(postData).then(response => {
        if (response.data && response.data.success === true) {
          this.sent = true;
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }
      });
    },
    async terminateAccount(code, language) {
      this.errors = {};
      this.loading = true;

      if (code === null) {
        this.errors.code = 'emptyCode';
        this.loading = false;
        return;
      }

      const token = await window.LoginAPI.getToken();
      if (token !== null) {
        const currentUser = JSON.parse(localStorage.getItem('userProfile'));
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(`https://paywall.delfi.lt/paywall/api/user/${currentUser.id}/delete-user-consents`, { headers });
      }

      const response = await window.LoginAPI.deleteCustomer({ language, code });
      if (response.data && response.data.success === true) {
        this.deleted = true;
        this.$store.dispatch('setUserProfile', null);
        setTimeout(() => {
          this.$router.push({ name: 'auth' });
        }, 3000);
      } else if (response.errors && response.errors.length) {
        this.errors = {};
        this.handleApiErrors(response.errors);
      } else {
        this.errors.code = 'invalidCode';
      }
    },
    async createLoginToken(email) {
      this.errors = {};
      this.loading = true;

      if (email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      const reCaptchaVersion = await window.LoginAPI.getRecaptchaVersion('createLoginToken-g-recaptcha');
      const response = await window.LoginAPI.createLoginToken({ email, reCaptchaVersion });

      this.loading = false;

      if (response.data && response.data.success === true) {
        this.loginTokenCreated = true;
      } else if (response.errors && response.errors.length) {
        if (response.errors[0].message.includes('CaptchaError: Captcha score too low')) {
          await window.LoginAPI.initRecaptchaV2('createLoginToken-g-recaptcha');
          return;
        }

        this.errors = {};
        this.handleApiErrors(response.errors);
      } else {
        this.errors.unknownError = 'somethingWentWrongError';
      }
    },
    loginWithToken(token) {
      this.errors = {};
      this.loading = true;

      if (token === null) {
        this.errors.code = 'emptyCode';
        this.loading = false;
        return;
      }

      window.LoginAPI.loginWithToken({ token }).then(response => {
        if (!response.data.status) {
          this.errors.code = 'invalidCode';
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }
        this.loading = false;
      });
    },
    handleApiErrors(errors) {
      if (errors && errors.length) {
        errors.forEach(error => {
          const mappedError = apiErrorsMap[error.error.type] || null;
          if (mappedError) {
            this.errors[mappedError] = error.error.type;
          } else {
            this.errors.unknownError = error.error.message;
          }

          if (window.ga) {
            window.ga('send', 'event', 'Login', 'Error', error.error.type);
          }
        });
      }
    },
  },
};
