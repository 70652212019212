import axios from 'axios';

export const checkUserDataObject = async (id, targetElementClassName) => {
  const token = await window.LoginAPI.getToken();
  const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
  const headers = { Authorization: `Bearer ${token}` };
  const query = `
    query {
      getCustomerData(input: { key: "bookmark", value: "${id}" }) {
        items {
          id
        }
      }
    }
  `;

  try {
    const response = await axios.post(url, { query }, { headers });
    if (response.data.data.getCustomerData && response.data.data.getCustomerData.items.length > 0) {
      const targetElement = document.getElementsByClassName(targetElementClassName);
      if (targetElement.length) {
        targetElement.forEach(item => {
          item.classList.add('active');
        });
      }

      return response.data.data.getCustomerData.items[0];
    }
  } catch (error) {
    console.log(error);
  }
};

export const createUserDataObject = async (id, targetElementClassName) => {
  const token = await window.LoginAPI.getToken();
  const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
  const headers = { Authorization: `Bearer ${token}` };
  const query = `
    mutation {
      createCustomerData(input: { key: "bookmark", value: "${id}" }) {
        status
        customerData {
          id
        }
      }
    }
  `;

  try {
    const response = await axios.post(url, { query }, { headers });
    if (response.data.data.createCustomerData && response.data.data.createCustomerData.status === true) {
      const targetElement = document.getElementsByClassName(targetElementClassName);
      if (targetElement.length) {
        targetElement.forEach(item => {
          item.classList.add('active');
        });
      }
    } else if (response.data.errors) {
      response.data.errors.forEach(error => {
        console.log(error);
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeUserDataObject = async (id, targetElementClassName) => {
  const token = await window.LoginAPI.getToken();
  const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
  const headers = { Authorization: `Bearer ${token}` };
  const query = `
    mutation {
      deleteCustomerData(input: { id: "${id}" }) {
        status
      }
    }
  `;

  try {
    const response = await axios.post(url, { query }, { headers });
    if (response.data.data.deleteCustomerData.status === true) {
      const targetElement = document.getElementsByClassName(targetElementClassName);
      if (targetElement.length) {
        targetElement.forEach(item => {
          item.classList.remove('active');
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
