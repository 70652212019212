export default {
  methods: {
    async loadComponent(template, view) {
      try {
        return require(`@/templates/${template}/components/${view}.vue`);
      } catch (e) {
        return require(`@/templates/default/components/${view}.vue`);
      }
    },
  },
};
