import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '../locales/en.yml';
import lt from '../locales/lt_LT.yml';
import ru from '../locales/lt_RU.yml';

// Use i18n
Vue.use(VueI18n);

export default new VueI18n({
  locale: 'lt',
  messages: {
    lt,
    en,
    ru,
  },
});
