<template>
  <div class="col-9 notifications">
    <h1 class="view-title text-left">
      {{ $t('notifications') }}
      <span v-if="notifications.length"> ({{ notifications.filter(x => x.status === 'not_seen').length }}) </span>
    </h1>
    <div class="notifications-filter">
      <div class="scroll-wrapper">
        <ul>
          <li>
            <button :class="{ active: userFilterKey == 'all' }" @click.prevent="userFilterKey = 'all'">
              {{ $t('notificationsFilterAll') }}
            </button>
          </li>
          <li v-for="serviceType in notificationsServiceTypes" :key="serviceType">
            <button :class="{ active: userFilterKey == serviceType }" @click.prevent="userFilterKey = serviceType">
              {{ $t(`${serviceType}`) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="notifications-inner view-inner mt-3 mt-sm-0">
      <transition-group v-if="!loading && this.notifications.length > 0" name="list-complete" tag="div">
        <div
          v-for="notification in userFilter.filter(x => x.status != 'deleted')"
          :key="notification.id"
          :id="notification.id"
          class="notification mt-3 mt-sm-0 list-complete-item"
          :class="notification.status"
          :data-service-type="notification.serviceType"
          :set="(notificationPayload = notification.notificationData.payload)"
        >
          <component
            :is="notificationComponent"
            :notification-id="notification.id"
            :notification-payload="notificationPayload"
            :notification-service-type="notification.serviceType"
            :notification-created-at="notification.createdAt"
          />
        </div>
      </transition-group>
      <div v-else-if="!loading" class="notifications-inner mt-3 mt-sm-0">
        <div class="pt-4">{{ $t('noNotifications') }}</div>
      </div>
      <div
        v-if="!loading && this.notifications.length > 0"
        class="notifications-controls d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between"
      >
        <button class="btn btn-blue" @click="loadMoreNotifications">
          {{ $t('loadMoreNotifications') }}
        </button>
        <button class="btn btn-blue-outline" @click="setAllNotificationsSeen">
          {{ $t('clearNotifications') }}
        </button>
      </div>
      <Loading v-if="loading" inner />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import i18n from '@/i18n';
import store from '@/store';
import config from '@/config';
import componentLoader from '@/mixins/componentLoader';
import Loading from '@/components/Loading.vue';

moment.locale(i18n.locale);
Vue.use(require('vue-moment'), { moment });

export default {
  data() {
    return {
      isApp: false,
      loading: true,
      notifications: store.getters.notifications || null,
      notificationsLimit: config.notificationsPerPage,
      page: 0,
      notificationsServiceTypes: ['comment_reply', 'comment_reaction'],
      userFilterKey: 'all',
    };
  },
  mixins: [componentLoader],
  components: {
    Loading,
  },
  computed: {
    notificationComponent() {
      return () => this.loadComponent(store.getters.template || this.$parent.$props.template, 'Notifications/Notification');
    },
    userProfile: {
      get: () => store.getters.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
    orderedNotifications() {
      return this.notifications.slice().sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt === b.createdAt) {
          if (a.status > b.status) {
            return 1;
          }
          return -1;
        }
        return -1;
      });
    },
    userFilter() {
      return this[this.userFilterKey];
    },
    all() {
      return this.notifications;
    },
    comment_reply() {
      return this.notifications.filter(x => x.serviceType === 'comment_reply');
    },
    comment_reaction() {
      return this.notifications.filter(x => x.serviceType === 'comment_reaction');
    },
  },
  created() {
    this.getNotificationsPublic({ limit: this.notificationsLimit });
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'notifications_view' });
  },
  methods: {
    constructQuery(searchParams) {
      if (searchParams) {
        const defaultParams = {
          limit: this.notificationsLimit,
          offset: this.page,
          orderBy: 'CREATED_AT',
          orderDirection: 'DESC',
        };

        if (searchParams) {
          Object.entries(searchParams).forEach(([key, value]) => {
            defaultParams[key] = value;
          });
        }

        let defaultParamsString = JSON.stringify(defaultParams);
        defaultParamsString = defaultParamsString.replace(/"/g, '');

        const query = `query {
          getNotificationsPublic (
            input: {pager:  ${defaultParamsString},
            status: [seen, not_seen]
            serviceType: ["comment_reaction", "comment_reply"]
          })
          {
            items {
              id
              channel
              contact
              notificationData
              status
              createdAt
              serviceType
            }
            pager {
              limit
              offset
              count
            }
          }
        }`;
        return query;
      }
      return false;
    },
    getNotificationsPublic(searchParams = {}) {
      window.LoginAPI.getToken().then(token => {
        const query = this.constructQuery(searchParams);
        const instance = axios.create({
          baseURL: process.env.VUE_APP_NOTIFICATIONS_API_URL,
          timeout: 5000,
          headers: { Authorization: `Bearer ${token}` },
        });

        instance.get(`?query=${encodeURI(query)}`).then(response => {
          if (response.data.data) {
            const seenNotifications = response.data.data.getNotificationsPublic.items;
            seenNotifications.forEach(element => {
              store.dispatch('setNotifications', element);
            });
          }
          this.loading = false;
        });
      });
    },
    updateNotificationStatus(notificationId, status) {
      if (notificationId && status) {
        const index = this.notifications.findIndex(x => x.id === notificationId);
        if (index !== -1) {
          if (this.notifications[index].status !== status) {
            window.NotificationsAPI.updateNotificationStatus(notificationId, status);
            this.notifications[index].status = status;
          }
        }
      }
    },
    getCurrentUserId() {
      const currentUser = JSON.parse(localStorage.getItem('userProfile'));
      if (currentUser.id) {
        return currentUser.id;
      }
      return false;
    },
    setAllNotificationsSeen() {
      this.notifications.forEach(notification => {
        this.updateNotificationStatus(notification.id, 'seen');
      });
    },
    loadMoreNotifications() {
      this.page = this.page + 1;

      const offset = this.notificationsLimit * this.page;
      this.getNotificationsPublic({ limit: this.notificationsLimit, offset });
    },
  },
};
</script>
