<template>
  <div class="headline d-flex align-items-start align-items-sm-center mb-2 mb-sm-4 pb-2 pb-sm-4">
    <div class="headline-image">
      <a class="img-link flex-shrink-1" :href="article.web_url">
        <img :src="article.pictures[0].url" width="240" height="117" :alt="stripTags(article.title)" />
      </a>
    </div>
    <h3 class="headline-title pl-2 pl-sm-4">
      <a :href="article.web_url" class="CBarticleTitle">{{ stripTags(article.title) }}</a>
      <span class="headline-date d-block mt-2">{{ this.dateFormat(article.publish_time) }}</span>
    </h3>
    <a class="headline-remove px-4" href="#" v-on:click.prevent="removeBookmark">
      <span class="fal fa-times"></span>
    </a>
  </div>
</template>
<script>
import Vue from 'vue';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import { stripTags } from '@/utils/common';

Vue.use(VueFilterDateFormat);

export default {
  data() {
    return {
      deleted: false,
      stripTags,
    };
  },
  props: {
    index: Number,
    article: Object,
  },
  methods: {
    removeBookmark() {
      this.$parent.removeBookmark(this.index, this.article.external_id);
    },
    dateFormat(dateInput) {
      const d = new Date(dateInput);
      return `${d.toISOString().slice(0, 10)}, ${d.toLocaleTimeString().slice(0, 5)}`;
    },
  },
};
</script>
