<template>
  <div v-if="$parent.$props.modal" class="modal-scroll-container inner">
    <a href="#" class="link-x" v-on:click.prevent="close">
      <i class="fal fa-times"></i>
    </a>
    <div class="modal-body">
      <h2>{{ $t('tosTitle') }}</h2>
      <div v-html="$t('tosText')"></div>
    </div>
  </div>
  <div v-else>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <a href="#" class="link-x" v-on:click.prevent="close">
              <i class="fal fa-times"></i>
            </a>
            <div class="modal-scroll-container">
              <div class="modal-body">
                <h2>{{ $t('tosTitle') }}</h2>
                <div v-html="$t('tosText')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  methods: {
    close() {
      this.$parent.closeTos();
    },
  },
  mounted() {
    if (this.$parent.$props.modal) {
      const modal = document.getElementsByClassName('inner');
      if (modal.length) {
        console.log(modal[0].parentElement.clientHeight);
        modal[0].style.height = `${modal[0].parentElement.clientHeight}px`;
      }
    }
  },
};
</script>
