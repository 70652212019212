<template>
  <div id="loading-overlay" :class="{ inner: this.inner }">
    <div id="loading">
      <img src="~@/assets/images/l.svg" width="60px" height="60px" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inner: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
