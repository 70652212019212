<template>
  <div class="col-3 sidebar">
    <div class="sidebar-inner">
      <nav v-if="showHeadInMobile" class="nav-mobile d-block d-sm-none">
        <span class="arrow-left">
          <i class="fa fa-chevron-left" />
        </span>
        <div class="scroll-wrapper">
          <ul class="nav">
            <li
              v-for="(item, index) in navItems"
              v-bind:key="index"
              class="nav-item"
              :data-view="navItems[index].name"
              v-bind:class="[
                navActive.route === item.name ? 'active' : '',
                navActive.route === 'home' && item.isHome === true ? 'active' : '',
                item.hidden === true ? 'd-none' : '',
              ]"
            >
              <router-link v-if="item.name != 'logout'" :to="'/' + item.name" class="nav-link">
                <span>{{ $t(item.name) }}</span>
              </router-link>
              <a v-else @click.prevent="logout()">
                <span>{{ $t(item.name) }}</span>
              </a>
            </li>
          </ul>
        </div>
        <span class="arrow-right">
          <i class="fa fa-chevron-right" />
        </span>
      </nav>
      <div v-else class="nav-back">
        <a @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
          {{ $t('back') }}
        </a>
      </div>
      <div
        class="profile-head pt-sm-0 pb-sm-0 py-md-5"
        v-bind:class="{
          'py-0': navActive.route === 'bookmarks',
          'pt-4 pb-5': navActive.route === 'profile',
          'hide-in-mobile': !showHeadInMobile,
        }"
      >
        <div
          class="profile-head-inner d-md-flex justify-content-start align-items-center flex-wrap"
          v-bind:class="{ 'd-none': navActive.route === 'bookmarks' }"
        >
          <div class="avatar mx-auto m-sm-0">
            <span
              v-if="this.userProfile && this.userProfile.avatar && hasAvatar"
              width="150"
              height="150"
              alt=""
              :style="{ backgroundImage: 'url(' + this.userProfile.avatar + ')' }"
            >
            </span>
            <span v-else>
              <i class="fas fa-user"></i>
            </span>
            <div v-if="showUpload" class="avatar-upload-select d-flex justify-content-around align-items-center px-2">
              <div class="avatar-upload-select-upload">
                <i class="fas fa-camera" v-bind:class="{ 'text-danger': errors.avatar }" @click.prevent="$refs.file.click()"></i>
              </div>
              <div class="avatar-upload-select-seperator">
                <i></i>
              </div>
              <div class="avatar-upload-select-delete" @click.prevent="removeAvatar()">
                <i class="fas fa-trash-alt"></i>
              </div>
            </div>
            <div class="avatar-upload" @click.prevent="showUpload = !showUpload">
              <i v-bind:class="[showUpload ? 'fal fa-times' : 'fas fa-camera']"></i>
            </div>
            <input id="avatar-input" class="d-none" ref="file" type="file" @change="uploadAvatar(fileInput)" />
          </div>
          <div v-if="errors.avatar" class="flex-grow-1 invalid-feedback">
            {{ $t(errors.avatar) }}
          </div>
        </div>
      </div>
      <nav class="nav-desktop d-none d-sm-block">
        <ul class="nav flex-column">
          <li
            v-for="(item, index) in navItems"
            v-bind:key="index"
            class="nav-item"
            :data-view="navItems[index].name"
            v-bind:class="[
              navActive.route === item.name ? 'active' : '',
              navActive.route === 'home' && item.isHome === true ? 'active' : '',
              item.hidden === true ? 'd-none' : '',
            ]"
          >
            <router-link v-if="item.name != 'logout'" :to="'/' + item.name" class="nav-link">
              <i v-bind:class="'fal fa-' + item.icon"></i>
              <span>{{ $t(item.name) }}</span>
            </router-link>
            <a v-else @click.prevent="logout()">
              <i v-bind:class="'fal fa-' + item.icon"></i>
              <span>{{ $t(item.name) }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import config from '../config';
import store from '../store';
import loginApi from '../mixins/loginApi';

export default {
  mixins: [loginApi],
  props: {
    showNavInMobile: {
      type: Boolean,
      default: () => false,
    },
    showHeadInMobile: {
      type: Boolean,
      default: () => false,
    },
    navActive: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nav: config.nav,
      showUpload: false,
      errors: {},
      dropdownOpen: false,
      fileInput: null,
      hasAvatar: false,
    };
  },
  computed: {
    userProfile: {
      get: () => store.state.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
    navItems: {
      get: () => config.nav.filter(item => !item.hidden).map(item => item),
    },
  },
  mounted() {
    if (this.userProfile.avatar) {
      var avatar = new Image();
      avatar.onload = () => (this.hasAvatar = true);
      avatar.onerror = () => (this.hasAvatar = false);
      avatar.src = this.userProfile.avatar;
    }

    this.fileInput = window.document.getElementById('avatar-input');

    // Mock m.delfi perfect scrollbar arrow navigation
    if (document.getElementsByClassName('nav-mobile').length) {
      const nm = document.getElementsByClassName('nav-mobile')[0];
      if (nm.getElementsByClassName('scroll-wrapper').length) {
        const sw = nm.getElementsByClassName('scroll-wrapper')[0];
        const al = sw.previousElementSibling;
        const ar = sw.nextElementSibling;
        sw.addEventListener('scroll', e => {
          const sp = e.target.scrollLeft;
          const mp = e.target.scrollWidth - e.target.clientWidth;

          al.style.display = sp > 0 ? 'block' : 'none';
          ar.style.display = sp >= mp ? 'none' : 'block';
        });

        al.addEventListener('click', () => (sw.scrollLeft -= 100));
        ar.addEventListener('click', () => (sw.scrollLeft += 100));
      }
    }
  },
};
</script>
