<template>
  <div class="auth-register">
    <template>
      <h2>{{ $t('pliusRegister') }} <br /></h2>
      <form action="#" class="needs-validation" novalidate @submit.prevent="handleSignup({ email, firstName, phone, password })" autocomplete="off">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.email }"
            :placeholder="$t('email')"
            v-model="email"
            autocomplete="email"
            required
          />
          <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.firstName }"
            :placeholder="$t('firstname')"
            v-model="firstName"
            autocomplete="given-name"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="tel"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.phone }"
            :placeholder="$t('pliusPhone')"
            v-model="phone"
            autocomplete="off"
            required
          />
        </div>
        <div class="form-group position-relative mb-3">
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="password"
            autocomplete="off"
            :placeholder="$t('password')"
            v-bind:class="{ 'is-invalid': errors.password }"
            required
          />
          <div id="showPassword" class="show-password position-absolute" @click.prevent="showPassword">
            <i class="far fa-eye" />
          </div>
          <div v-if="errors.password" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.password) }}</div>
          <div v-if="errors.firstName || errors.phone" class="invalid-feedback"><i class="far fa-times" /> {{ $t('mandatoryFields') }}</div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="tosInput" class="form-check-input" type="checkbox" v-bind:class="{ 'is-invalid': errors.tosAgreed }" v-model="tosAgreed" value="" />
            <label class="form-check-label" for="tosInput">
              {{ $t('tosLabel') }}
              <a href="https://www.delfi.lt/apie/?page=privatumas" target="_blank">{{ $t('ppLink') }}</a>
              {{ $t('and') }}
              <a href="https://www.delfi.lt/apie/?page=id-taisykles" target="_blank">{{ $t('tosLink') }}</a>
            </label>
            <div v-if="errors.tosAgreed" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.tosAgreed) }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="marketingConsentInput" class="form-check-input" type="checkbox" v-model="marketingConsent" value="" />
            <label class="form-check-label" for="marketingConsentInput">
              {{ $t('marketingConsentLabel') }}
            </label>
          </div>
        </div>
        <div class="form-group mt-4 d-flex justify-content-center align-items-center">
          <div class="mx-auto text-center">
            <div id="signup-g-recaptcha" />
            <button type="submit" class="btn btn-blue">{{ $t('register') }}</button>
          </div>
        </div>
      </form>
    </template>
    <Loading v-if="loading" />
    <Tos v-if="showTos" @close="showTos = false" />
  </div>
</template>

<script>
import config from '@/config';
import store from '@/store';
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';
import Tos from '@/templates/default/components/Auth/Tos.vue';

require('custom-event-polyfill');

export default {
  mixins: [loginApi],
  data() {
    return {
      config,
      loading: false,
      email: store.getters.email || window.localStorage.getItem('pwUserEmail') || null,
      firstName: window.localStorage.getItem('pwUserName') || null,
      phone: window.localStorage.getItem('pwUserPhone') ? window.localStorage.getItem('pwUserPhone').replace('+370', '') : null,
      password: null,
      marketingConsent: false,
      tosAgreed: false,
      showTos: false,
      errors: {},
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
    Tos,
  },
  methods: {
    showPassword() {
      const password = document.getElementById('password');
      const showPasswordBtn = document.getElementById('showPassword').getElementsByTagName('i')[0];
      if (showPasswordBtn.classList.contains('fa-eye')) {
        password.type = 'text';
        showPasswordBtn.classList.remove('fa-eye');
        showPasswordBtn.classList.add('fa-eye-slash');
      } else {
        password.type = 'password';
        showPasswordBtn.classList.remove('fa-eye-slash');
        showPasswordBtn.classList.add('fa-eye');
      }
    },
    showLogin() {
      this.$parent.renderLogin();
    },
    closeTos() {
      this.showTos = false;
    },
    handleSignup({ email, firstName, phone, password }) {
      this.errors = {};
      this.loading = true;

      if (firstName === null) {
        this.errors.firstName = 'pliusNameEmpty';
      }

      if (phone === null || phone === '') {
        this.errors.phone = 'pliusPhoneEmpty';
      }

      if (Object.keys(this.errors).length) {
        this.loading = false;
        return;
      }

      const phoneFiltered = phone.replace(/\s/g, '');
      this.signup({ email, firstName, phone: phoneFiltered, password });
    },
  },
};
</script>
