<template>
  <div class="auth-login auth-login-with-token">
    <div v-if="errors.code">
      <p class="pt-5 pb-4 text-center">
        {{ $t(errors.code) }}
        <br />
        <br />
        <a href="#" @click.prevent="showLogin">{{ $t('backToLogin') }}</a>
      </p>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from '@/components/Loading.vue';

export default {
  data() {
    return {
      loading: false,
      email: null,
      code: null,
      errors: {},
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
  },
  methods: {
    showLogin() {
      this.$parent.renderLogin();
    },
    handleLoginWithToken(token) {
      this.errors = {};
      this.loading = true;

      if (token === null) {
        this.errors.code = 'emptyCode';
        this.loading = false;
        return;
      }

      window.LoginAPI.loginWithToken({ token }).then(response => {
        if (response.data.status && response.data.status === true && localStorage.getItem('pwPaymentRef')) {
          location.href = localStorage.getItem('pwPaymentRef');
        } else if (!response.data.status) {
          this.errors.code = 'invalidCode';
        } else if (response.errors && response.errors.length) {
          this.errors = {};
          this.handleApiErrors(response.errors);
        } else {
          this.errors.unknownError = 'somethingWentWrongError';
        }
        this.loading = false;
      });
    },
  },
  created() {
    if (this.$route.params.code) {
      Vue.set(this, 'code', this.$route.params.code);
    }
  },
  mounted() {
    this.loading = true;
    if (this.code !== null) {
      this.handleLoginWithToken(this.code);
    }
  },
};
</script>
