<template>
  <div class="col-9 consents">
    <h1 class="view-title text-left">
      {{ $t('messagingSettings') }}
    </h1>
    <div class="consents-inner view-inner">
      <div v-if="!loading">
        <div class="form-check mb-5">
          <label class="form-check-label form-check-toggle">
            <input class="form-check-input" type="checkbox" v-model="consents.marketingConsent" @click="handleSetConsent('marketingConsent', 'true')" />
            <span>{{ $t('marketingConsentLabel') }}</span>
          </label>
        </div>
        <template v-if="hasDelfiPlusService || consents.dplius_filter_mail[4] || consents.dplius_filter_mail[1]">
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[4]" @click="handleSetConsent('dplius_filter_mail', '4')" />
              <span>{{ $t('delfiPlusMarketingConsentLabel') }}</span>
            </label>
          </div>
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[1]" @click="handleSetConsent('dplius_filter_mail', '1')" />
              <span>{{ $t('delfiPlusContentConsentLabel') }}</span>
            </label>
          </div>
        </template>
        <template v-if="hasM360Service || consents.dplius_filter_mail[5] || consents.dplius_filter_mail[6]">
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[5]" @click="handleSetConsent('dplius_filter_mail', '5')" />
              <span>{{ $t('m360MarketingConsentLabel') }}</span>
            </label>
          </div>
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[6]" @click="handleSetConsent('dplius_filter_mail', '6')" />
              <span>{{ $t('m360ContentConsentLabel') }}</span>
            </label>
          </div>
        </template>
        <template v-if="hasLoginLtService || consents.dplius_filter_mail[7] || consents.dplius_filter_mail[8]">
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[7]" @click="handleSetConsent('dplius_filter_mail', '7')" />
              <span>{{ $t('loginLtMarketingConsentLabel') }}</span>
            </label>
          </div>
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[8]" @click="handleSetConsent('dplius_filter_mail', '8')" />
              <span>{{ $t('loginLtContentConsentLabel') }}</span>
            </label>
          </div>
        </template>
        <template v-if="hasVerslasPlusService || consents.dplius_filter_mail[9] || consents.dplius_filter_mail[10]">
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[9]" @click="handleSetConsent('dplius_filter_mail', '9')" />
              <span>{{ $t('verslasPlusMarketingConsentLabel') }}</span>
            </label>
          </div>
          <div class="form-check mb-5">
            <label class="form-check-label form-check-toggle">
              <input class="form-check-input" type="checkbox" v-model="consents.dplius_filter_mail[10]" @click="handleSetConsent('dplius_filter_mail', '10')" />
              <span>{{ $t('verslasPlusContentConsentLabel') }}</span>
            </label>
          </div>
        </template>
      </div>
      <Loading v-if="loading" inner />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import config from '@/config';
import store from '@/store';
import loginApi from '@/mixins/loginApi';
import userDataApi from '@/mixins/userDataApi';
import Loading from '@/components/Loading.vue';

export default {
  mixins: [loginApi, userDataApi],
  data() {
    return {
      config,
      loading: true,
      hasDelfiPlusService: false,
      hasM360Service: false,
      hasLoginLtService: false,
      hasVerslasPlusService: false,
      consents: {
        marketingConsent: false,
        dplius_filter_mail: {
          1: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
          10: false,
        },
      },
    };
  },
  components: {
    Loading,
  },
  computed: {
    userProfile: {
      get: () => store.getters.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
  },
  methods: {
    async handleSetConsent(type, value) {
      if (this.checkConsent(type, value)) {
        await this.deleteConsent(type, value, () => {
          setTimeout(() => {
            this.getConsents();
          }, 500);
        });
      } else {
        await this.createConsent(type, value, () => {
          setTimeout(() => {
            this.getConsents();
          }, 500);
        });
      }
    },
    async checkForServices(callback) {
      this.loading = true;
      await window.LoginAPI.getToken().then(async token => {
        const headers = { Authorization: `Bearer ${token}` };
        await axios.get(`https://paywall.delfi.lt/paywall/api/user/${this.userProfile.id}/service-info`, { headers }).then(response => {
          const responseData = response.data;
          if (Array.isArray(responseData)) {
            response.data.forEach(item => {
              if (item.valid) {
                if (item.service.id === 1) {
                  this.hasDelfiPlusService = true;
                }

                if (item.service.id === 3) {
                  this.hasM360Service = true;
                }

                if (item.service.id === 9) {
                  this.hasLoginLtService = true;
                }

                if (item.service.id === 10) {
                  this.hasVerslasPlusService = true;
                }
              }
            });
          }

          if (typeof callback === 'function') {
            callback();
          }
        });
      });
    },
  },
  mounted() {
    Vue.set(this, 'loading', true);

    this.getConsents(() => {
      this.checkForServices(() => {
        this.loading = false;
      });
    });
  },
};
</script>
