export function initializeModal() {
  if (window.loginModalInitialized) {
    return;
  }

  const modalContainer = document.createElement('div');
  modalContainer.className = 'login-modal-container';

  const modalScrollContainer = document.createElement('div');
  modalScrollContainer.className = 'login-modal-scroll-container';

  const modalContent = document.createElement('div');
  modalContent.id = 'login-modal';
  modalContent.className = 'login-modal-content';

  const modalClose = document.createElement('a');
  modalClose.className = 'login-modal-close';

  const modalCloseIcon = document.createElement('i');
  modalCloseIcon.className = 'fa fa-times';

  modalClose.appendChild(modalCloseIcon);
  modalContent.appendChild(modalClose);
  modalScrollContainer.appendChild(modalContent);
  modalContainer.appendChild(modalScrollContainer);
  document.body.appendChild(modalContainer);

  document.getElementsByClassName('login-modal-close')[0].addEventListener('click', event => {
    event.preventDefault();
    document.getElementsByClassName('login-modal-container')[0].classList.remove('active');
    document.getElementsByClassName('login-modal-content')[0].lastChild.remove();
    return false;
  });
}

export function showModal() {
  const modal = document.getElementsByClassName('login-modal-container')[0];
  if (modal) {
    modal.classList.add('active');
  }
}

export function closeModal() {
  const modal = document.getElementsByClassName('login-modal-container')[0];
  if (modal) {
    modal.classList.remove('active');
    if (document.getElementsByClassName('login-modal-content')[0].lastChild.nodeName === 'DIV') {
      document.getElementsByClassName('login-modal-content')[0].lastChild.remove();
    }
  }
}
