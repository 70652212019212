<template>
  <div class="auth-login">
    <div v-if="emailCheckStatus && loginTokenCreated">
      <h2>{{ $t('pliusLoginWithoutPasswordH2') }} <br /></h2>
      <div class="mx-auto m-sm-0 text-center">
        <p>{{ $t('pliusLoginWithouPasswordSentP1', { email }) }}</p>
        <p>{{ $t('pliusLoginWithouPasswordSentP2') }}</p>
        <p>{{ $t('pliusLoginSupportMsg') }}</p>
      </div>
    </div>
    <div v-else-if="!emailCheckStatus && socialLoginEmailNeeded">
      <h2>{{ $t('pliusLogInOrRegister') }} <br /></h2>
      <form action="#" class="needs-validation" novalidate @submit.prevent="handleSignupSocialPlius(socialEmail)">
        <div class="mx-auto m-sm-0 text-center">
          <p v-if="errors.socialAuthError">{{ $t(errors.socialAuthError) }}</p>
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.email }"
            v-bind:placeholder="$t('email')"
            v-model="socialEmail"
            autocomplete="email"
            required
          />
          <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
        </div>
        <div class="form-group">
          <div class="mx-auto m-sm-0 text-center">
            <button type="submit" class="btn btn-blue">
              {{ $t('pliusNext') }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-else-if="!emailCheckStatus && !socialLoginEmailNeeded">
      <h2>{{ $t('pliusLogIn') }} <br /></h2>
      <form action="#" class="needs-validation" novalidate @submit.prevent="handleCheckEmail(email)">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.email }"
            v-bind:placeholder="$t('email')"
            v-model="email"
            autocomplete="email"
            required
          />
          <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
        </div>
        <div class="form-group">
          <div class="mx-auto m-sm-0 text-center">
            <div id="checkEmail-g-recaptcha" />
            <button type="submit" class="btn btn-blue">
              {{ $t('pliusNext') }}
            </button>
          </div>
        </div>
        <div class="or mt-5 mb-4">
          <hr />
          <span>{{ $t('pliusOrLoginWith') }}</span>
        </div>
        <div class="text-center text-gray fs-13 mb-4">
          {{ $t('pliusSocialTosLabel') }}
          <a href="https://www.delfi.lt/apie/?page=privatumas" target="_blank">{{ $t('ppLink') }}</a>
          {{ $t('and') }}
          <a href="https://www.delfi.lt/apie/?page=id-taisykles" target="_blank">{{ $t('tosLink') }}</a>
        </div>
        <div
          v-if="!isApp || isFacebookNativeLoginSupported || isGoogleNativeLoginSupported || isAppleNativeLoginSupported"
          class="form-group mb-5 d-flex flex-wrap justify-content-center align-items-start"
        >
          <div v-if="!isApp || isFacebookNativeLoginSupported" class="text-center">
            <a href="#" class="btn btn-fb" @click.prevent="loginSocialPlius('FACEBOOK')">
              <img :src="require(`@/assets/images/fb.svg`)" height="18" />
            </a>
          </div>
          <div v-if="(!isApp || isGoogleNativeLoginSupported) && !isBlockedUserAgent" class="ml-2 mr-1 text-center">
            <a href="#" class="btn btn-g" @click.prevent="loginSocialPlius('GOOGLE')">
              <img :src="require(`@/assets/images/g.svg`)" height="16" />
            </a>
          </div>
          <div v-if="!isApp || isAppleNativeLoginSupported" class="ml-1 mr-2 text-center">
            <a href="#" class="btn btn-apple" @click.prevent="loginSocialPlius('APPLE')">
              <img :src="require(`@/assets/images/apple.svg`)" height="18" />
            </a>
          </div>
        </div>
        <div v-else class="in-app-social-auth-disabled" v-html="$t('inAppSocialAuthDisabledText')" />
        <div v-if="errors.socialAuthError || socialEmailNotAvailable" class="invalid-feedback d-block mt-3 text-center">
          {{ $t(errors.socialAuthError) }}
        </div>
      </form>
    </div>
    <div v-else>
      <h2>{{ $t('pliusLogIn') }} <br /></h2>
      <form action="#" class="needs-validation" novalidate @submit.prevent="login(email, password)">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.email }"
            v-bind:placeholder="$t('email')"
            v-model="email"
            autocomplete="email"
            required
          />
          <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
        </div>
        <div class="form-group position-relative">
          <input
            id="password"
            type="password"
            class="form-control"
            v-bind:placeholder="$t('password')"
            autocomplete="current-password"
            v-bind:class="{ 'is-invalid': errors.password || errors.missingPassword }"
            v-model="password"
            required
          />
          <div id="showPassword" class="show-password position-absolute" @click.prevent="showPassword">
            <i class="far fa-eye" />
          </div>
          <div v-if="errors.password" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.password) }}</div>
          <div v-if="errors.missingPassword" class="invalid-feedback">
            <i class="far fa-times" />
            <span>
              {{ $t('PasswordMissingError') }} <a href="#" @click.prevent="showRecoverPassword">{{ $t('forgotPassword') }}</a> {{ $t('PasswordMissingError_') }}
            </span>
          </div>
        </div>
        <div class="mb-5 text-right">
          <a class="link-forgot" href="#" @click.prevent="showRecoverPassword">
            {{ $t('forgotPassword') }}
          </a>
        </div>
        <div class="form-group">
          <div class="mx-auto m-sm-0 text-center">
            <div id="login-g-recaptcha" />
            <button type="submit" class="btn btn-blue btn-block-60">
              {{ $t('login') }}
            </button>
          </div>
        </div>
        <div class="form-group">
          <div class="mx-auto m-sm-0 text-center">
            <div id="createLoginToken-g-recaptcha" />
            <button type="button" class="btn btn-blue-outline btn-block-60" @click.prevent="createLoginToken(email)" :disabled="loginTokenCreated">
              {{ $t('pliusLoginWithoutPassword') }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import store from '@/store';
import loginApi from '@/mixins/loginApi';
import { validEmail, dispatchEvent } from '@/utils/common';
import Loading from '@/components/Loading.vue';

require('custom-event-polyfill');

export default {
  mixins: [loginApi],
  data() {
    return {
      emailCheckStatus: false,
      loading: false,
      email: window.localStorage.getItem('pwUserEmail') || null,
      password: null,
      loginTokenCreated: false,
      errors: {},
      socialResponse: null,
      socialType: null,
      socialLoginEmailNeeded: false,
      socialEmailNotAvailable: false,
      socialEmail: null,
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
  },
  methods: {
    async handleCheckEmail(email) {
      const emailAvailable = await this.checkEmail(email);
      if (Object.keys(this.errors).length === 0) {
        this.emailCheckStatus = true;
        if (emailAvailable) {
          store.commit('setEmail', email);
          this.$parent.renderRegister();
        }
      }
    },
    loginSocialPlius(type) {
      this.errors = {};

      window.LoginAPI.resolveAuthMethod(type, 'login').then(response => {
        if (response === undefined) {
          dispatchEvent('login_fe_signUp', type);
          return;
        }

        if (response.signup) {
          this.socialResponse = response;
          this.socialType = type;
          this.signupSocialPlius(response, type);
        }
      });
    },
    signupSocialPlius(response, type, email) {
      response
        .signup(email)
        .then(() => {
          this.loading = false;
          dispatchEvent('login_fe_signUp', type);
          return;
        })
        .catch(e => {
          if (e.type === 'ResolveAuthNoEmail') {
            this.loading = false;
            this.errors.socialAuthError = 'socialResolveAuthNoEmail';
            this.socialLoginEmailNeeded = true;
          }
          if (e.type === 'EmailNotAvailableError') {
            this.loading = false;
            this.errors.socialAuthError = 'socialEmailNotAvailableError';
            this.emailCheckStatus = false;
            this.socialLoginEmailNeeded = false;
            this.socialEmailNotAvailable = true;
          }
        });
    },
    handleSignupSocialPlius(email) {
      this.errors = {};
      this.loading = true;

      if (email === null) {
        this.errors.email = 'emptyEmail';
        this.loading = false;
        return;
      }

      if (!validEmail(email)) {
        this.errors.email = 'invalidEmail';
        this.loading = false;
        return;
      }

      this.signupSocialPlius(this.socialResponse, this.socialType, email);
    },
    showPassword() {
      const password = document.getElementById('password');
      const showPasswordBtn = document.getElementById('showPassword').getElementsByTagName('i')[0];
      if (showPasswordBtn.classList.contains('fa-eye')) {
        password.type = 'text';
        showPasswordBtn.classList.remove('fa-eye');
        showPasswordBtn.classList.add('fa-eye-slash');
      } else {
        password.type = 'password';
        showPasswordBtn.classList.remove('fa-eye-slash');
        showPasswordBtn.classList.add('fa-eye');
      }
    },
    showRecoverPassword() {
      this.$parent.renderRecoverPassword();
    },
  },
};
</script>
