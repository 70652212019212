<template>
  <div class="col-9 bookmarks">
    <h1 class="view-title text-left">{{ $t('bookmarks') }}</h1>
    <div class="bookmarks-inner view-inner mt-3 mt-sm-0">
      <div v-if="!loading">
        <template v-if="this.articles.length > 0">
          <div v-for="(article, index) in this.articles" v-bind:key="article.id">
            <component :is="articleComponent" :article="article" :index="index" />
          </div>
          <div v-if="this.pages > 1 && this.page < this.pages" class="d-flex align-items-center justify-content-center">
            <a href="#" class="btn btn-blue" v-on:click.prevent="getMoreArticles">Daugiau</a>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="text-center">{{ $t('noBookmarks') }}</p>
          </div>
        </template>
      </div>
      <Loading v-if="loading" inner />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import config from '@/config';
import { isApp } from '@/utils/common';
import componentLoader from '@/mixins/componentLoader';
import Loading from '@/components/Loading.vue';

export default {
  data() {
    return {
      isApp: false,
      loading: true,
      articleIds: [],
      articles: [],
      deletedIds: [],
      pages: 0,
      page: 1,
    };
  },
  mixins: [componentLoader],
  components: {
    Loading,
  },
  computed: {
    articleComponent() {
      return () => this.loadComponent(store.getters.template || this.$parent.$props.template, 'Bookmarks/Article');
    },
    userProfile: {
      get: () => store.getters.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
    userData: {
      get: () => store.getters.userData,
      set: userData => store.commit('setUserData', userData),
    },
  },
  methods: {
    async getBookmarks() {
      const token = await window.LoginAPI.getToken();
      const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
      const headers = { Authorization: `Bearer ${token}` };
      const query = `
        query {
          getCustomerData(input: { key: "bookmark" }) {
            items {
              id
              customerId
              key
              value
              createdAt
            }
          }
        }
      `;

      try {
        const response = await axios.post(url, { query, operationName: null }, { headers });
        if (response.data.data.getCustomerData && response.data.data.getCustomerData.items.length > 0) {
          this.$store.commit('setUserData', response.data.data.getCustomerData.items);
          Vue.set(this, 'pages', Math.ceil(response.data.data.getCustomerData.items.length / config.bookmarksPerPage));
          response.data.data.getCustomerData.items.forEach((item, index) => {
            const page = Math.ceil((index + 1) / config.bookmarksPerPage);
            if (!this.articleIds[page - 1]) {
              this.articleIds.push([]);
            }
            this.articleIds[page - 1].push(`"${item.value}"`);
          });
          if (this.articleIds.length > 0) {
            this.getArticles(this.articleIds[this.page - 1], () => {
              Vue.set(this, 'loading', false);
            });
          }
        } else {
          Vue.set(this, 'loading', false);
        }
      } catch (error) {
        console.log(error);
        Vue.set(this, 'loading', false);
      }
    },
    async removeBookmark(index) {
      const token = await window.LoginAPI.getToken();
      const bookmark = this.userData[index];
      const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
      const headers = { Authorization: `Bearer ${token}` };
      const query = `
        mutation {
          deleteCustomerData(input: { id: "${bookmark.id}" }) {
            status
          }
        }
      `;

      try {
        const response = await axios.post(url, { query }, { headers });
        if (response.data.data.deleteCustomerData.status === true) {
          this.userData.splice(index, 1);
          this.articles.splice(index, 1);
          Vue.set(this, 'articles', this.articles);
          this.$store.commit('setUserData', this.userData);
        } else if (response.data.errors) {
          response.data.errors.forEach(error => {
            console.log(error);
          });
        }
      } catch (error) {
        console.log(error);
        Vue.set(this, 'loading', false);
      }
    },
    async createBookmark(id) {
      if (!/[0-9]{8}/.test(id)) {
        console.log(`"${id}" does not appear to be valid article ID`);
        return;
      }

      const token = await window.LoginAPI.getToken();
      const url = process.env.VUE_APP_CUSTOMER_DATA_API_URL;
      const headers = { Authorization: `Bearer ${token}` };
      const query = `
        mutation {
          createCustomerData(input: { key: "bookmark", value: "${id}" }) {
            status
            customerData {
              id
            }
          }
        }
      `;

      try {
        const response = await axios.post(url, { query }, { headers });
        if (response.data.data.createCustomerData && response.data.data.createCustomerData.status === true) {
          this.getBookmarks();
        } else if (response.data.errors) {
          response.data.errors.forEach(error => {
            console.log(error);
          });
        }
      } catch (error) {
        console.log(error);
        Vue.set(this, 'loading', false);
      }
    },
    async getArticles(articleIds, callback) {
      const url = process.env.VUE_APP_CONTENT_API_URL;
      const query = `
        query {
          getArticles(externalId: [${articleIds}]) {
            items {
              id
              external_id
              title
              publish_time
              web_url
              pictures {
                url(size: w480h234)
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post(url, { query });
        if (response.data.data.getArticles) {
          this.articles.push(...response.data.data.getArticles.items);
          const articleIds = this.articleIds[this.page - 1].map(id => id.replace(/"/g, ''));
          this.articles.sort((a, b) => articleIds.indexOf(a.external_id) - articleIds.indexOf(b.external_id));
        }

        if (typeof callback === 'function') {
          callback();
        }
      } catch (error) {
        console.log(error);
        Vue.set(this, 'loading', false);
      }
    },
    getMoreArticles() {
      Vue.set(this, 'page', this.page + 1);
      this.getArticles(this.articleIds[this.page - 1]);
    },
  },
  created() {
    Vue.set(this, 'isApp', isApp());
    Vue.set(this, 'loading', true);
    this.getBookmarks();
  },
};
</script>
