export default {
  nav: [
    {
      name: 'profile',
      icon: 'user-circle',
      isHome: true,
    },
    {
      name: 'subscriptions',
      icon: 'bell',
    },
    {
      name: 'consents',
      icon: 'pen-alt',
    },
    {
      name: 'bookmarks',
      icon: 'bookmark',
    },
    {
      name: 'notifications',
      icon: 'comment-alt-lines',
    },
    {
      name: 'logout',
      icon: 'sign-out-alt',
      hiddenInDropdown: true,
    },
  ],
  bookmarksPerPage: 10,
  notificationsPerPage: 10,
  handleLoginAnchorInternally: false,
  consentTypes: ['marketingConsent', 'dplius_filter_mail'],
};
