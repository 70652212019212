<template>
  <div class="auth-register">
    <template v-if="registerSuccess">
      <p class="pt-5 pb-4 text-center">
        {{ $t('registerSuccess') }}
        <br />
        <br />
        <a class="btn btn-blue" href="#" @click.prevent="showLogin">
          {{ $t('login') }}
        </a>
      </p>
    </template>
    <template v-else>
      <h2>{{ $t('registerH2') }} <br /></h2>
      <form action="#" class="needs-validation" novalidate @submit.prevent="signup({ email, phone, password })">
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-bind:class="{ 'is-invalid': errors.email }"
            :placeholder="$t('email')"
            v-model="email"
            autocomplete="email"
            required
          />
          <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
        </div>
        <div class="form-group">
          <input type="tel" class="form-control" v-bind:class="{ 'is-invalid': errors.phone }" :placeholder="$t('phone')" v-model="phone" autocomplete="tel" />
          <div v-if="errors.phone" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.phone) }}</div>
        </div>
        <div class="form-group position-relative mb-3">
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="password"
            autocomplete="current-password"
            :placeholder="$t('password')"
            v-bind:class="{ 'is-invalid': errors.password }"
            required
          />
          <div id="showPassword" class="show-password position-absolute" @click.prevent="showPassword">
            <i class="far fa-eye" />
          </div>
          <div v-if="errors.password" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.password) }}</div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="tosInput" class="form-check-input" type="checkbox" v-bind:class="{ 'is-invalid': errors.tosAgreed }" v-model="tosAgreed" value="" />
            <label class="form-check-label" for="tosInput">
              {{ $t('tosLabel') }}
              <a href="https://www.delfi.lt/apie/?page=privatumas" target="_blank">{{ $t('ppLink') }}</a>
              {{ $t('and') }}
              <a href="https://www.delfi.lt/apie/?page=id-taisykles" target="_blank">{{ $t('tosLink') }}</a>
            </label>
            <div v-if="errors.tosAgreed" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.tosAgreed) }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="marketingConsentInput" class="form-check-input" type="checkbox" v-model="marketingConsent" value="" />
            <label class="form-check-label" for="marketingConsentInput">
              {{ $t('marketingConsentLabel') }}
            </label>
          </div>
        </div>
        <div class="form-group mt-4 d-flex justify-content-center align-items-center">
          <div class="mx-auto text-center">
            <div id="signup-g-recaptcha" />
            <button type="submit" class="btn btn-blue">{{ $t('register') }}</button>
          </div>
        </div>
        <div class="or mt-5 mb-5">
          <hr />
          <span>{{ $t('orRegisterWith') }}</span>
        </div>
        <div
          v-if="!isApp || isFacebookNativeLoginSupported || isGoogleNativeLoginSupported || isAppleNativeLoginSupported"
          class="form-group mb-5 d-flex flex-wrap justify-content-center align-items-start"
        >
          <div v-if="!isApp || isFacebookNativeLoginSupported" class="text-center">
            <a href="#" class="btn btn-fb" @click.prevent="signupSocial('FACEBOOK')">
              <img :src="require(`@/assets/images/fb.svg`)" height="18" />
            </a>
          </div>
          <div v-if="(!isApp || isGoogleNativeLoginSupported) && !isBlockedUserAgent" class="mx-2 text-center">
            <a href="#" class="btn btn-g" @click.prevent="signupSocial('GOOGLE')">
              <img :src="require(`@/assets/images/g.svg`)" height="16" />
            </a>
          </div>
          <div v-if="!isApp || isAppleNativeLoginSupported" class="text-center">
            <a href="#" class="btn btn-apple" @click.prevent="signupSocial('APPLE')">
              <img :src="require(`@/assets/images/apple.svg`)" height="18" />
            </a>
          </div>
        </div>
        <div v-else class="in-app-social-auth-disabled" v-html="$t('inAppSocialAuthDisabledText')" />
        <div v-if="errors.socialAuthError" class="invalid-feedback d-block mt-3 text-center">
          {{ $t(errors.socialAuthError) }}
        </div>
      </form>
      <div class="text-center fs-16">
        {{ $t('alreadyHaveAnAccount') }}
        <strong>
          <a href="#" @click.prevent="showLogin">{{ $t('logIn') }}</a>
        </strong>
      </div>
    </template>
    <Loading v-if="loading" />
    <Tos v-if="showTos" @close="showTos = false" />
  </div>
</template>

<script>
import Vue from 'vue';
import config from '@/config';
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';
import Tos from './Tos.vue';

require('custom-event-polyfill');

export default {
  mixins: [loginApi],
  data() {
    return {
      config,
      loading: false,
      registerSuccess: false,
      email: null,
      phone: null,
      password: null,
      marketingConsent: false,
      tosAgreed: false,
      showTos: false,
      errors: {},
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
    Tos,
  },
  methods: {
    showPassword() {
      const password = document.getElementById('password');
      const showPasswordBtn = document.getElementById('showPassword').getElementsByTagName('i')[0];
      if (showPasswordBtn.classList.contains('fa-eye')) {
        password.type = 'text';
        showPasswordBtn.classList.remove('fa-eye');
        showPasswordBtn.classList.add('fa-eye-slash');
      } else {
        password.type = 'password';
        showPasswordBtn.classList.remove('fa-eye-slash');
        showPasswordBtn.classList.add('fa-eye');
      }
    },
    showLogin() {
      this.$parent.renderLogin();
    },
    closeTos() {
      this.showTos = false;
    },
  },
  mounted() {
    Vue.set(this, 'registerSuccess', false);
  },
};
</script>
