import config from '../config';
import { isMobile, isPortalRoot } from './common';

export function createLoginDropdown(i18n) {
  if (document.getElementsByClassName('login-dropdown').length || isPortalRoot()) {
    return;
  }

  const loginAnchor = document.getElementById(window.loginAnchorId);
  if (!loginAnchor) {
    return;
  }

  const loginDropdown = document.createElement('div');
  loginDropdown.className = 'login-dropdown';

  config.nav.forEach(item => {
    const navItemIcon = document.createElement('i');
    navItemIcon.className = `fal fa-${item.icon}`;
    const navItem = document.createElement('a');
    navItem.href = `https://www.delfi.lt/id/#/${item.name}`;
    navItem.setAttribute('data-view', item.name);
    if (item.hidden === true || item.hiddenInDropdown === true) {
      navItem.classList.add('d-none');
    }
    const navItemText = document.createElement('span');
    navItemText.innerHTML = i18n.t(item.name);
    navItem.appendChild(navItemIcon);
    navItem.appendChild(navItemText);
    navItem.addEventListener('click', () => {
      loginDropdown.classList.toggle('visible');
    });
    loginDropdown.appendChild(navItem);
  });

  const navItemLogout = document.createElement('a');
  navItemLogout.className = 'btn btn-gray';
  navItemLogout.innerHTML = i18n.t('logout');
  navItemLogout.addEventListener('click', () => {
    window.LoginAPI.logout();
  });

  loginDropdown.appendChild(navItemLogout);

  if (isMobile()) {
    document.body.appendChild(loginDropdown);
  } else {
    loginAnchor.after(loginDropdown);
  }

  if (!config.handleLoginAnchorInternally) {
    loginAnchor.addEventListener('click', () => {
      loginDropdown.classList.toggle('visible');
    });

    document.body.addEventListener('click', event => {
      if (!loginAnchor.contains(event.target) && !loginDropdown.contains(event.target)) {
        if (loginDropdown.classList.contains('visible')) {
          loginDropdown.classList.remove('visible');
        }
      }
    });
  }
}

export function removeLoginDropdown() {
  const loginDropdown = document.getElementsByClassName('login-dropdown');
  if (loginDropdown.length) {
    loginDropdown[0].remove();
  }
}
