<template>
  <div class="auth-login">
    <h2>{{ $t('logIn') }} <br /></h2>
    <form action="#" class="needs-validation" novalidate @submit.prevent="login(email, password)">
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          v-bind:class="{ 'is-invalid': errors.email }"
          v-bind:placeholder="$t('email')"
          v-model="email"
          autocomplete="email"
          required
        />
        <div v-if="errors.email" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.email) }}</div>
      </div>
      <div class="form-group position-relative">
        <input
          id="password"
          type="password"
          class="form-control"
          v-bind:placeholder="$t('password')"
          autocomplete="current-password"
          v-bind:class="{ 'is-invalid': errors.password || errors.missingPassword }"
          v-model="password"
          required
        />
        <div id="showPassword" class="show-password position-absolute" @click.prevent="showPassword">
          <i class="far fa-eye" />
        </div>
        <div v-if="errors.password" class="invalid-feedback"><i class="far fa-times" /> {{ $t(errors.password) }}</div>
        <div v-if="errors.missingPassword" class="invalid-feedback">
          <i class="far fa-times" />
          <span>
            {{ $t('PasswordMissingError') }} <a href="#" @click.prevent="showRecoverPassword">{{ $t('forgotPassword') }}</a> {{ $t('PasswordMissingError_') }}
          </span>
        </div>
      </div>
      <div class="mb-4">
        <a class="link-forgot" href="#" @click.prevent="showRecoverPassword">
          {{ $t('forgotPassword') }}
        </a>
      </div>
      <div class="form-group">
        <div class="mx-auto m-sm-0 text-center">
          <div id="login-g-recaptcha" />
          <button type="submit" class="btn btn-blue">
            {{ $t('login') }}
          </button>
        </div>
      </div>
      <div class="or mt-5 mb-5">
        <hr />
        <span>{{ $t('orLoginWith') }}</span>
      </div>
      <div
        v-if="!isApp || isFacebookNativeLoginSupported || isGoogleNativeLoginSupported || isAppleNativeLoginSupported"
        class="form-group mb-5 d-flex flex-wrap justify-content-center align-items-start"
      >
        <div v-if="!isApp || isFacebookNativeLoginSupported" class="text-center">
          <a href="#" class="btn btn-fb" @click.prevent="loginSocial('FACEBOOK')">
            <img :src="require(`@/assets/images/fb.svg`)" height="18" />
          </a>
        </div>
        <div v-if="(!isApp || isGoogleNativeLoginSupported) && !isBlockedUserAgent" class="ml-2 mr-1 text-center">
          <a href="#" class="btn btn-g" @click.prevent="loginSocial('GOOGLE')">
            <img :src="require(`@/assets/images/g.svg`)" height="16" />
          </a>
        </div>
        <div v-if="!isApp || isAppleNativeLoginSupported" class="ml-1 mr-2 text-center">
          <a href="#" class="btn btn-apple" @click.prevent="loginSocial('APPLE')">
            <img :src="require(`@/assets/images/apple.svg`)" height="18" />
          </a>
        </div>
      </div>
      <div v-else class="in-app-social-auth-disabled" v-html="$t('inAppSocialAuthDisabledText')" />
      <div v-if="errors.socialAuthError" class="invalid-feedback d-block mt-3 text-center">
        {{ $t(errors.socialAuthError) }}
      </div>
    </form>
    <div class="text-center fs-16">
      {{ $t('doNotHaveAnAccount') }}
      <strong>
        <a href="#" @click.prevent="showRegister">{{ $t('register') }}</a>
      </strong>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';

require('custom-event-polyfill');

export default {
  mixins: [loginApi],
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      errors: {},
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
  },
  methods: {
    showPassword() {
      const password = document.getElementById('password');
      const showPasswordBtn = document.getElementById('showPassword').getElementsByTagName('i')[0];
      if (showPasswordBtn.classList.contains('fa-eye')) {
        password.type = 'text';
        showPasswordBtn.classList.remove('fa-eye');
        showPasswordBtn.classList.add('fa-eye-slash');
      } else {
        password.type = 'password';
        showPasswordBtn.classList.remove('fa-eye-slash');
        showPasswordBtn.classList.add('fa-eye');
      }
    },
    showRegister() {
      this.$parent.renderRegister();
    },
    showRecoverPassword() {
      this.$parent.renderRecoverPassword();
    },
  },
};
</script>
