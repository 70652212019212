<template>
  <div class="auth">
    <component :is="component" :key="view" :modal="$parent.$props.modal" />
  </div>
</template>

<script>
import store from '@/store';
import componentLoader from '@/mixins/componentLoader';

export default {
  data() {
    return {
      view: null,
    };
  },
  mixins: [componentLoader],
  computed: {
    component() {
      store.commit('setTemplate', this.$parent.$props.template);
      store.commit('setView', this.view);
      return () => this.loadComponent(this.$parent.$props.template, this.view);
    },
  },
  methods: {
    renderLogin() {
      this.view = 'Auth/Login';
    },
    renderRegister() {
      this.view = 'Auth/Register';
    },
    renderRecoverPassword() {
      this.view = 'Auth/RecoverPassword';
    },
    renderLoginWithToken() {
      this.view = 'Auth/LoginWithToken';
    },
  },
  created() {
    if (this.$parent.$props.forceRegister) {
      this.renderRegister();
    } else if (this.$route.name === 'recoverPassword' || this.$route.name === 'resetPassword') {
      this.renderRecoverPassword();
    } else if (this.$route.name === 'loginWithToken') {
      this.renderLoginWithToken();
    } else {
      this.renderLogin();
    }
  },
};
</script>
