import Vue from 'vue';
import config from './config';
import store from './store';
import router from './router';
import i18n from './i18n';
import App from './App.vue';
import { updateLoginAnchor, handleLoginAnchorClick, updateNotificationsCount } from './utils/common';
import { createLoginDropdown, removeLoginDropdown } from './utils/dropdown';
import { initializeModal, showModal, closeModal } from './utils/modal';
import { checkUserDataObject, createUserDataObject, removeUserDataObject } from './utils/userData';

Vue.config.productionTip = false;

// Set loginAnchorId if not defined before
if (!window.loginAnchorId) {
  window.loginAnchorId = '_login';
}

window.loginModalInitialized = false;
window.loginFeInitialized = false;

const getNotifications = () => {
  if (window.NotificationsAPI) {
    window.NotificationsAPI.getNotifications(99, 0, 'not_seen', ['comment_reaction', 'comment_reply'], true).then(res => {
      if (res) {
        updateNotificationsCount(window.loginAnchorId, res.notifications.length);
      }
    });
  }
};

const bindLoginAnchorClick = () => {
  if (!config.handleLoginAnchorInternally) {
    return;
  }

  if (!window.loginFeInitialized) {
    var loginAnchor = document.getElementById(window.loginAnchorId);
    loginAnchor.addEventListener('click', () => handleLoginAnchorClick(window.loginAnchorId));
  }
};

const bindLogout = () => {
  window.LoginAPI.on('login_api_logout', () => {
    updateLoginAnchor(window.loginAnchorId, false, null);
    removeLoginDropdown();
    closeModal();
    store.dispatch('setUserProfile', null);
    store.dispatch('setUserData', null);
  });
};

const initialize = callback => {
  if (window.loginFeInitialized) {
    if (typeof callback === 'function') {
      callback();
    }

    return;
  }

  if (window.LoginAPI.isModuleInited()) {
    window.LoginAPI.getToken().then(token => {
      if (token !== null) {
        window.LoginAPI.getCustomers().then(response => {
          if (response && response.data) {
            store.dispatch('setUserProfile', response.data);
            createLoginDropdown(i18n);
            updateLoginAnchor(window.loginAnchorId, true, response.data);
            bindLoginAnchorClick(window.loginAnchorId);
            getNotifications();
            window.loginFeInitialized = true;
            if (typeof callback === 'function') {
              callback();
            }
          }
        });
      } else {
        updateLoginAnchor(window.loginAnchorId, false);
        bindLoginAnchorClick(window.loginAnchorId);
        window.loginFeInitialized = true;
        if (typeof callback === 'function') {
          callback();
        }
      }

      bindLogout();
    });
  } else {
    window.LoginAPI.on('login_api_init', ({ detail }) => {
      if (detail && detail.loginStatus !== false) {
        createLoginDropdown(i18n);
        updateLoginAnchor(window.loginAnchorId, true, detail);
        bindLoginAnchorClick(window.loginAnchorId);
        getNotifications();
        window.loginFeInitialized = true;
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        updateLoginAnchor(window.loginAnchorId, false);
        bindLoginAnchorClick(window.loginAnchorId);
        window.loginFeInitialized = true;
        if (typeof callback === 'function') {
          callback();
        }
      }

      bindLogout();
    });
  }
};

// Wait for LoginAPI before initialize()
const _waitForLoginApi = () => {
  let waitForLoginApi;
  if (typeof window.LoginAPI !== 'undefined') {
    initialize();
    clearTimeout(waitForLoginApi);
  } else {
    waitForLoginApi = setTimeout(_waitForLoginApi, 500);
  }
};

_waitForLoginApi();

window.addEventListener('notifications_api_notification_received', ({ detail }) => {
  if (window.LoginAPI && window.LoginAPI.isModuleInited()) {
    window.LoginAPI.getToken().then(token => {
      if (token !== null) {
        store.dispatch('setNotifications', detail);
        updateNotificationsCount(window.loginAnchorId, store.getters.notifications.filter(x => x.status === 'not_seen').length);
      }
    });
  }
});

const renderApp = (divId, params) => {
  i18n.locale = params.locale || 'lt';

  // Add global router guard
  router.beforeEach(async (to, from, next) => {
    const token = await window.LoginAPI.getToken();
    if (to.matched.some(route => route.meta.requiresAuth) && token === null) {
      if (params.getParams !== undefined) {
        const getParams = JSON.parse(params.getParams);
        if (
          typeof getParams === 'object' &&
          getParams.view &&
          getParams.code &&
          (getParams.view === 'reset-password' || getParams.view === 'login-with-token')
        ) {
          router.push({ path: `/auth/${getParams.view}/${getParams.code}` });
        }
      } else {
        router.push({ path: '/auth' });
      }
    } else {
      next();
    }
  });

  const vm = new Vue({
    router,
    i18n,
    store,
    render: h =>
      h(App, {
        props: params,
      }),
  }).$mount();
  if (params.modal && window.loginModalInitialized === true && document.getElementById('login-app')) {
    if (document.getElementById('login-app').parentNode.id === 'login-modal') {
      document.getElementById('login-app').remove();
    }
  }
  document.getElementById(divId).appendChild(vm.$el);
  if (params.modal) {
    showModal();
  }
};

window.showLoginEmbed = (divId, params) => {
  if (params.modal) {
    if (!window.loginModalInitialized) {
      initializeModal();
      window.loginModalInitialized = true;
    }
  }

  initialize(() => {
    renderApp(divId, params);
  });
};

window.loginAction = (successCallback, forceRegister) => {
  let locale = 'lt';

  if (window.location.hostname === 'ru.delfi.lt' || window.location.pathname.indexOf('/ru/') === 0) {
    locale = 'ru';
  } else if (window.location.hostname === 'en.delfi.lt' || window.location.pathname.indexOf('/en/') === 0) {
    locale = 'en';
  } else if (window.location.hostname === 'pl.delfi.lt' || window.location.pathname.indexOf('/pl/') === 0) {
    locale = 'en';
  }

  window.showLoginEmbed('login-modal', {
    modal: true,
    loginAnchorId: window.loginAnchorId,
    locale,
    successCallback,
    forceRegister,
  });
};

/*
 * Deprecated
 */
window.createLoginDropdown = () => {
  createLoginDropdown(i18n);
};

window.createBookmark = async (id, targetElementClassName) => {
  if (!/[0-9]/.test(id)) {
    console.log(`"${id}" does not appear to be valid article ID`);
    return;
  }
  if (window.LoginAPI && window.LoginAPI.isModuleInited()) {
    const token = await window.LoginAPI.getToken();
    if (token !== null) {
      await createUserDataObject(id, targetElementClassName);
    } else {
      window.loginAction(async () => {
        await createUserDataObject(id, targetElementClassName);
      });
    }
  } else {
    window.LoginAPI.on('login_api_init', async ({ detail }) => {
      if (detail && detail.loginStatus !== false) {
        await createUserDataObject(id, targetElementClassName);
      } else {
        window.loginAction(async () => {
          await createUserDataObject(id, targetElementClassName);
        });
      }
    });
  }
};

window.checkBookmark = async (id, targetElementClassName) => {
  if (!/[0-9]/.test(id)) {
    console.log(`"${id}" does not appear to be valid article ID`);
    return;
  }
  if (window.LoginAPI && window.LoginAPI.isModuleInited()) {
    const token = await window.LoginAPI.getToken();
    if (token !== null) {
      await checkUserDataObject(id, targetElementClassName);
    }
  } else {
    window.LoginAPI.on('login_api_init', async ({ detail }) => {
      if (detail && detail.loginStatus !== false) {
        await checkUserDataObject(id, targetElementClassName);
      }
    });
  }
};

window.removeBookmark = async (id, targetElementClassName) => {
  if (!/[0-9]/.test(id)) {
    console.log(`"${id}" does not appear to be valid article ID`);
    return;
  }
  if (window.LoginAPI && window.LoginAPI.isModuleInited()) {
    const token = await window.LoginAPI.getToken();
    if (token !== null) {
      const item = await checkUserDataObject(id, targetElementClassName);
      if (item && item.id) {
        await removeUserDataObject(item.id, targetElementClassName);
      }
    }
  } else {
    window.LoginAPI.on('login_api_init', async ({ detail }) => {
      if (detail && detail.loginStatus !== false) {
        const item = await checkUserDataObject(id, targetElementClassName);
        if (item && item.id) {
          await removeUserDataObject(item.id, targetElementClassName);
        }
      }
    });
  }
};
