import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    email: null,
    template: null,
    view: null,
    userProfile: JSON.parse(localStorage.getItem('userProfile')) || null,
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    userConsents: [],
    notifications: [],
    subscriptionsWasShown: false,
  },
  mutations: {
    setEmail(state, data) {
      this.state.email = data;
    },
    setTemplate(state, data) {
      this.state.template = data;
    },
    setView(state, data) {
      this.state.view = data;
    },
    setUserProfile(state, data) {
      this.state.userProfile = data;
      localStorage.setItem('userProfile', JSON.stringify(data));
    },
    setUserData(state, data) {
      this.state.userData = data;
      localStorage.setItem('userData', JSON.stringify(data));
    },
    setUserConsents(state, data) {
      this.state.userConsents = data;
    },
    setNotifications(state, data) {
      const index = this.state.notifications.findIndex(x => x.id === data.id);

      if (data.status !== 'pending' && data.status !== 'deleted') {
        if (index === -1) {
          this.state.notifications.push(data);
        } else {
          this.state.notifications[index].state = data.state;
        }
      }
    },
    setSubscriptionsWasShown(state, data) {
      this.state.subscriptionsWasShown = data;
    },
  },
  actions: {
    setEmail({ commit }, data) {
      commit('setEmail', data);
    },
    setTemplate({ commit }, data) {
      commit('setTemplate', data);
    },
    setView({ commit }, data) {
      commit('setView', data);
    },
    setUserProfile({ commit }, data) {
      commit('setUserProfile', data);
    },
    setUserData({ commit }, data) {
      commit('setUserData', data);
    },
    setUserConsents({ commit }, data) {
      commit('setUserConsents', data);
    },
    setNotifications({ commit }, data) {
      commit('setNotifications', data);
    },
    setSubscriptionsWasShown({ commit }, data) {
      commit('setSubscriptionsWasShown', data);
    },
  },
  getters: {
    email: state => state.email,
    template: state => state.template,
    view: state => state.view,
    userProfile: state => state.userProfile,
    userData: state => state.userData,
    userConsents: state => state.userConsents,
    notifications: state => state.notifications,
    subscriptionsWasShown: state => state.subscriptionsWasShown,
  },
});
