<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <a href="#" class="link-x" v-on:click.prevent="close">
            <i class="fal fa-times"></i>
          </a>
          <div class="modal-body">
            <div v-if="passwordUpdated">
                <p class="pt-5 pb-4 text-center">{{ $t('passwordUpdated') }}</p>
            </div>
            <div v-else>
              <h2>
                {{ $t('changePassword') }} <br />
                <small>{{ $t('hereYouCanChangeYourPassword') }}</small>
              </h2>
              <form action="#" class="needs-validation" novalidate
                @submit.prevent="updatePassword()">
                <div v-if="this.userProfile.hasPassword" class="form-group">
                  <input type="password" class="form-control"
                    :placeholder="$t('currentPassword')" v-model="currentPassword"
                    v-bind:class="{ 'is-invalid': errors.currentPassword }" />
                  <div v-if="errors.currentPassword" class="invalid-feedback">
                    {{ $t(errors.currentPassword) }}
                  </div>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control"
                    v-model="password" :placeholder="$t('newPassword')"
                    v-bind:class="{ 'is-invalid': errors.password }" />
                  <div v-if="errors.password" class="invalid-feedback">
                    {{ $t(errors.password) }}
                  </div>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control"
                    v-model="repeatPassword" :placeholder="$t('repeatNewPassword')"
                    v-bind:class="{ 'is-invalid': errors.repeatPassword }" />
                  <div v-if="errors.repeatPassword" class="invalid-feedback">
                    {{ $t(errors.repeatPassword) }}
                  </div>
                </div>
                <div v-if="errors.unknownError" class="d-block px-4 invalid-feedback text-center">
                  {{ $t(errors.unknownError) }}
                </div>
                <div class="form-group mt-4 d-flex justify-content-center align-items-center">
                  <div>
                    <button type="submit" class="btn btn-blue">{{ $t('change') }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from '@/store';
import loginApi from '@/mixins/loginApi';

export default {
  mixins: [loginApi],
  data() {
    return {
      passwordUpdated: false,
      currentPassword: null,
      password: null,
      repeatPassword: null,
      errors: {},
    };
  },
  computed: {
    userProfile: {
      get: () => store.getters.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
  },
  methods: {
    close() {
      this.$parent.closeChangePassword();
    },
  },
};
</script>
