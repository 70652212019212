<template>
  <div v-if="userProfile" class="col-9 profile">
    <h1 class="view-title text-left">
      {{ $t('profile') }}
      <small>{{ $t('profileID') }}: {{ userProfile.id }}</small>
    </h1>
    <div class="profile-inner view-inner">
      <form action="#" class="needs-validation" novalidate @submit.prevent="updateProfile()">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group mb-5">
              <label>{{ $t('firstname') }}</label>
              <input type="text" class="form-control" v-model="userProfile.firstName" :readonly="!isEditing ? true : false" autocomplete="given-name" />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-5 position-relative">
              <label>{{ $t('email') }}</label>
              <input
                type="text"
                class="form-control"
                v-model="email"
                v-bind:class="{
                  'is-invalid': errors.email,
                  verified: !isEditing && userProfile.emailVerified === true && userProfile.emailTmp === null,
                  'verification-pending': !isEditing && userProfile.emailTmp !== null,
                  unverified: !isEditing && userProfile.emailVerified !== true,
                }"
                :readonly="!isEditing ? true : false"
                autocomplete="email"
              />
              <div v-if="verifyEmailSent" class="valid-feedback">
                {{ $t('verifyEmailSent') }}
              </div>
              <div v-if="errors.email" class="invalid-feedback">
                {{ $t(errors.email) }}
              </div>
              <div v-if="errors.unknownError" class="invalid-feedback">
                {{ $t(errors.unknownError) }}
              </div>
              <div v-if="!isEditing && userProfile.emailTmp !== null" class="invalid-feedback text-warning">
                {{ $t('emailVerificationPending') }}
                <a class="text-warning" @click.prevent="sendVerifyEmail($parent.$props.locale)">
                  <u>{{ $t('verifyResend') }}</u>
                </a>
              </div>
              <div v-if="!isEditing && userProfile.emailVerified !== true" class="invalid-feedback text-warning">
                {{ $t('emailNotVerified') }}
                <a class="text-warning" @click.prevent="sendVerifyEmail($parent.$props.locale)">
                  <u>{{ $t('verify') }}</u>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group mb-5">
              <label>{{ $t('lastname') }}</label>
              <input type="text" class="form-control" v-model="userProfile.lastName" :readonly="!isEditing ? true : false" autocomplete="family-name" />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-5">
              <label>{{ $t('phoneNumber') }}</label>
              <input
                type="tel"
                class="form-control"
                v-model="userProfile.phone"
                v-bind:class="{ 'is-invalid': errors.phone }"
                :readonly="!isEditing ? true : false"
                autocomplete="tel"
              />
              <div v-if="errors.phone" class="invalid-feedback">
                {{ $t(errors.phone) }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group" v-bind:class="[isEditing ? 'mb-3' : 'mb-5']">
              <label>{{ $t('password') }}</label>
              <input
                type="password"
                class="form-control"
                :value="userProfile.hasPassword === 'true' ? 'password' : 'no-password'"
                :readonly="!isEditing ? true : false"
              />
              <div v-if="errors.displayName" class="invalid-feedback">
                {{ $t(errors.displayName) }}
              </div>
            </div>
            <a v-if="isEditing" href="#" class="btn btn-blue-outline mb-3" v-on:click.prevent="showChangePassword = true">
              {{ $t('changePassword') }}
            </a>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group mb-5">
              <label>{{ $t('displayName') }}</label>
              <input
                type="text"
                class="form-control"
                v-model="displayName"
                v-bind:class="{ 'is-invalid': errors.displayName }"
                :readonly="!isEditing ? true : false"
                autocomplete="nickname"
              />
              <div v-if="errors.displayName" class="invalid-feedback">
                {{ $t(errors.displayName) }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-5">
          <div v-if="isEditing">
            <button type="submit" class="btn btn-blue">
              {{ $t('update') }}
            </button>
            <a
              href="#"
              class="btn btn-blue-outline ml-2"
              @click.prevent="
                reloadProfile();
                isEditing = !isEditing;
              "
            >
              {{ $t('cancelEditing') }}
            </a>
          </div>
          <div v-else>
            <a href="#" class="btn btn-blue" @click.prevent="isEditing = !isEditing">
              {{ $t('editProfile') }}
            </a>
          </div>
        </div>
        <component :is="socialConnectComponent" />
        <div class="form-group mt-4">
          <a href="#" class="link-delete" @click.prevent="showDeleteAccount = true">
            {{ $t('deleteAccount') }}
          </a>
        </div>
      </form>
      <Loading v-if="loading" />
    </div>
    <component :is="changePasswordComponent" v-if="showChangePassword" />
    <component :is="deleteAccountComponent" v-if="showDeleteAccount" :locale="$parent.$props.locale" />
  </div>
</template>

<script>
import Vue from 'vue';
import config from '@/config';
import store from '@/store';
import componentLoader from '@/mixins/componentLoader';
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';

export default {
  mixins: [componentLoader, loginApi],
  components: {
    Loading,
  },
  data() {
    return {
      config,
      loading: true,
      isEditing: false,
      profileUpdated: false,
      verifyEmailSent: false,
      showChangePassword: false,
      showDeleteAccount: false,
      errors: {},
      displayName: store.state.userProfile.displayName || null,
      email: store.state.userProfile.email || null,
      initialEmail: store.state.userProfile.email || null,
      subscriptionsWasShown: store.state.subscriptionsWasShown,
      template: store.getters.template || this.$parent.$props.template,
    };
  },
  computed: {
    changePasswordComponent() {
      return () => this.loadComponent(this.template, 'Profile/ChangePassword');
    },
    deleteAccountComponent() {
      return () => this.loadComponent(this.template, 'Profile/DeleteAccount');
    },
    socialConnectComponent() {
      return () => this.loadComponent(this.template, 'Profile/SocialConnect');
    },
    userProfile: {
      get: () => store.state.userProfile,
      set: userProfile => store.commit('setUserProfile', userProfile),
    },
  },
  methods: {
    closeChangePassword() {
      this.showChangePassword = false;
    },
    closeDeleteAccount() {
      this.showDeleteAccount = false;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      toParams => {
        if (toParams.view == 'verify-email') {
          this.verifyEmail(toParams.code || null);
        }

        if (toParams.view == 'confirm-delete') {
          this.showDeleteAccount = true;
        }
      }
    );

    window.LoginAPI.on('login_api_logout', () => {
      this.$router.push({ name: 'auth' });
    });
  },
  mounted() {
    Vue.set(this, 'loading', false);
  },
};
</script>
