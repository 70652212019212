import Vue from 'vue';
import Router from 'vue-router';
import Auth from './views/Auth.vue';
import Profile from './views/Profile.vue';
import Subscriptions from './views/Subscriptions.vue';
import Consents from './views/Consents.vue';
import Bookmarks from './views/Bookmarks.vue';
import Notifications from './views/Notifications.vue';

Vue.use(Router);

export default new Router({
  mode: !/\/id/i.test(window.location.pathname) ? 'abstract' : 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Profile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/auth/recover-password',
      name: 'recoverPassword',
      component: Auth,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/auth/reset-password/:code',
      name: 'resetPassword',
      component: Auth,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/auth/login-with-token/:code',
      name: 'loginWithToken',
      component: Auth,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 0,
      },
    },
    {
      path: '/profile/verify-email/:code',
      name: 'verifyEmail',
      component: Profile,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 0,
      },
    },
    {
      path: '/profile/confirm-delete/:code',
      name: 'confirmDelete',
      component: Profile,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 0,
      },
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: Subscriptions,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 1,
      },
    },
    {
      path: '/consents',
      name: 'consents',
      component: Consents,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 2,
      },
    },
    {
      path: '/bookmarks',
      name: 'bookmarks',
      component: Bookmarks,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 3,
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications,
      meta: {
        requiresAuth: true,
      },
      props: {
        navIndex: 4,
      },
    },
  ],
});
