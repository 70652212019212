<template>
  <div class="auth-recover">
    <template v-if="reset">
      <div class="d-flex justify-content-center align-items-center">
        <p class="text-center">
          {{ $t('resetMsg') }}
          <br />
          <br />
          <a class="btn btn-blue" href="#" v-on:click.prevent="showLogin">
            {{ $t('login') }}
          </a>
        </p>
      </div>
    </template>
    <template v-else-if="codeSetFromUrl">
      <div class="d-flex justify-content-center align-items-center">
        <h4>{{ $t('recoverSetPasswordMsg') }}</h4>
      </div>
      <form action="#" class="needs-validation" novalidate v-on:submit.prevent>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            v-model="password"
            required
            v-bind:class="{ 'is-invalid': errors.password }"
            :placeholder="$t('password')"
          />
          <div v-if="errors.password" class="invalid-feedback">{{ $t(errors.password) }}</div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            v-model="repeatPassword"
            required
            v-bind:class="{ 'is-invalid': errors.repeatPassword }"
            :placeholder="$t('repeatPassword')"
          />
          <div v-if="errors.repeatPassword" class="invalid-feedback">
            {{ $t(errors.repeatPassword) }}
          </div>
        </div>
        <div class="form-group mt-4 d-flex justify-content-center align-items-center">
          <div>
            <button type="button" class="btn btn-blue" v-on:click.prevent="resetPassword(code, password, repeatPassword)">{{ $t('submit') }}</button>
          </div>
        </div>
      </form>
      <div v-if="errors.unknownError" class="d-block px-4 invalid-feedback text-center">
        {{ $t(errors.unknownError) }}
      </div>
      <a href="#" class="link-x" v-on:click.prevent="showLogin">
        <i class="fal fa-times"></i>
      </a>
    </template>
    <template v-else-if="sent">
      <h2>{{ $t('pliusRecoverPasswordH2') }} <br /></h2>
      <div class="mx-auto m-sm-0 text-center">
        <p>{{ $t('pliusRecoverPasswordP1', { email }) }}</p>
        <p>{{ $t('pliusLoginSupportMsg') }}</p>
      </div>
    </template>
    <template v-else>
      <h2 class="mb-4">
        {{ $t('forgotYourPassword') }}<br />
        <small>{{ $t('enterYourEmailAddress') }}</small>
      </h2>
      <form action="#" class="needs-validation" novalidate v-on:submit.prevent>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            :placeholder="$t('email')"
            v-model="email"
            v-bind:class="{ 'is-invalid': errors.email }"
            autocomplete="email"
          />
          <div v-if="errors.email" class="invalid-feedback">{{ $t(errors.email) }}</div>
        </div>
        <div class="form-group mt-4 d-flex justify-content-center align-items-center">
          <div class="mx-auto text-center">
            <div id="recoverPassword-g-recaptcha" />
            <button type="button" class="btn btn-blue" v-on:click.prevent="recoverPassword(email)">{{ $t('submit') }}</button>
          </div>
        </div>
        <div v-if="errors.unknownError" class="d-block px-4 invalid-feedback text-center">
          {{ $t(errors.unknownError) }}
        </div>
        <div class="form-group mt-2 d-flex justify-content-center align-items-center">
          <a class="link-back" href="#" v-on:click.prevent="showLogin">
            {{ $t('backToLogin') }}
          </a>
        </div>
      </form>
    </template>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store';
import loginApi from '@/mixins/loginApi';
import Loading from '@/components/Loading.vue';

export default {
  mixins: [loginApi],
  data() {
    return {
      loading: false,
      sent: false,
      reset: false,
      email: store.getters.email || window.localStorage.getItem('pwUserEmail') || null,
      code: null,
      codeSetFromUrl: false,
      password: null,
      repeatPassword: null,
      errors: {},
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    Loading,
  },
  methods: {
    showLogin() {
      this.$parent.renderLogin();
    },
  },
  created() {
    if (this.$route.name === 'resetPassword') {
      Vue.set(this, 'sent', 'true');
      if (this.$route.params.code) {
        Vue.set(this, 'code', this.$route.params.code);
        Vue.set(this, 'codeSetFromUrl', true);
      }
    }
  },
};
</script>
